
import { useEffect, useState } from "react"
import FullLaout from "../../component/FullLayout"
import useAPI from "../../hooks/common/useAPI"
import useOnReady from "../../hooks/useOnReady"
import { Toast } from "antd-mobile"
import BaseBox from "../../component/BaseBox"
import { ReactComponent as SVGNodata } from "../../images/svg/nodata.svg"
import { ReactComponent as SVGMore } from "../../images/svg/down.svg"
import useIsLogin from "../../hooks/common/useIsLogin"
import { useTranslation } from "react-i18next"
import useMoneyDeal from "../../hooks/common/useMoneyDeal"
const ListItem = ({ data,dealMoney }) => {
    const {t} = useTranslation()
    
    return <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', padding: "0.1rem 0rem", borderBottom: "1px solid #666" }}>
        <div style={{ lineHeight: "0.18rem", color: "#B1B5C3" }}>
            <div style={{ fontWeight: "bold", fontSize: "0.14rem", color: "#FCFCFD" }}>
                {t(data?.type)}
            </div>
            <div style={{ fontSize: "0.13rem", marginTop: "0.04rem" }}>{t('pay')}{dealMoney(data?.payed_money)}{t('currency')} </div>
            <div style={{ fontSize: "0.13rem" }}>{t('money')}{dealMoney(data?.after_money)}{t('currency')}</div>

        </div>
        <div style={{ display: "flex", justifyContent:"space-between", flexDirection: "column", alignItems: "end", textAlign:"right" }}>
            <div></div>
            <div>
            <div style={{ boxSizing: "border-box", padding: "0rem 0rem", fontSize: "0.2rem", fontWeight: 'bold', color: "#3772FF" }}>+{dealMoney(data?.money)}</div>
            <div style={{ fontSize: "0.13rem", color: "#B1B5C3" }}>{new Date(data?.create_time).format("yyyy-MM-dd HH:mm:ss")}</div>
            </div>
        </div>
    </div>

}


export default () => {
    const {t} = useTranslation()
    const { request } = useAPI()
    const { ready, readyed } = useOnReady()
    const [data, setData] = useState()
    const {isLogin} = useIsLogin()
    const {dealMoney} = useMoneyDeal()
    const [pager, setPager] = useState({ current: 1, total: 0, pageSize: 20 })
    const getPage = async (page) => {
        let rs = await request("/user/recharge_record", { page, pageSize: pager.pageSize, },'POST')
        if (rs?.result === true) {
            ready();
            if (parseInt(page) === 1) {
                setData(rs?.data?.data)

            } else {
                setData([...data, ...rs?.data?.data])

            }
            setPager(rs?.data?.pager)

        }
    }

    useEffect(()=>{
        if(isLogin()) getPage(1)
    }, [])

    return <FullLaout title={t('recharge_log')} rightIcon={<></>} showFooter = {false}>
        <BaseBox  style={{padding:"0rem 0.2rem"}}>
            
            {data?.map(tt => {
                    return <ListItem data={tt} key={tt?.id} dealMoney={dealMoney} />
                })}

            {(pager.pageSize * pager.current < pager.total) && <div style={{ display: 'flex', justifyContent: 'center', marginTop: "0.2rem", marginBottom: "0.4rem" }}>
                 <div className="btn" onClick={() => { getPage(parseInt(pager.current) + 1) }} style={{ display: "flex", alignItems: 'center', fontSize: "0.14rem", backgroundColor: "rgba(0,154,214,0.8)", boxSizing: "border-box", padding: "0.12rem 0.5rem", color: "white", borderRadius: "0.04rem" }}>
                     <div>{t('loading_more')}</div>
                     &nbsp;<SVGMore fill="#fff" style={{ width: "0.18rem", height: "0.18rem" }} />
                 </div>
             </div>}


            <div style={{ marginTop: "0.4rem", textAlign: 'center' }}>
                {(readyed && data?.length > 0 === false) && <div>
                <SVGNodata style={{ width: "1.2rem", height: "1.2rem", margin: "auto" }} />
                <div style={{ fontSize: "0.2rem", color: "#888", marginTop: "-0.16rem" }}>{t('no_data')}</div>
                </div>}
            </div> 
        </BaseBox>
                 
    </FullLaout>
}