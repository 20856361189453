import logo from './logo.svg';
import './App.css';
import Router from './pages/Router';
import enUS from 'antd-mobile/es/locales/en-US'
import { ConfigProvider, Toast } from 'antd-mobile'
import useLogin from './hooks/common/useLogin';
import { useEffect } from 'react';
import useAPI from './hooks/common/useAPI';
import MoreGames from './component/MoreGames';
import useModal from './hooks/component/useModal';
import IosTip from './component/IosTip';
import use51La from './hooks/common/use51La';
import { useTranslation } from 'react-i18next';
import useDownload from './hooks/common/useDownload';
import Tip18 from './component/Tip18';
import Qrcode from './component/Qrcode';

const params = new URLSearchParams(window.location.search)


//日期运算
Date.prototype.format = function (format) {
  if (!isNaN(this.valueOf())) {

    if (typeof (format) != "string") return this.toLocalString();
    var year = this.getFullYear().toString();
    var month = (this.getMonth() + 1).toString();;
    var date = this.getDate().toString();;
    var hour = this.getHours().toString();
    var minutes = this.getMinutes().toString();
    var sencond = this.getSeconds().toString();
    var ms = this.getMilliseconds().toString();
    format = format.replace("yyyy", year);
    format = format.replace("MM", month.length == 1 ? "0" + month : month);
    format = format.replace("dd", date.length == 1 ? "0" + date : date);
    format = format.replace("HH", hour.length == 1 ? "0" + hour : hour);
    format = format.replace("mm", minutes.length == 1 ? "0" + minutes : minutes);
    format = format.replace("ss", sencond.length == 1 ? "0" + sencond : sencond);
    format = format.replace("ff", ms.length == 1 ? "000" + ms : ms.length == 2 ? "00" + ms : ms.length == 3 ? "0" + ms : ms);
    format = format.replace("yy", year[2] + year[3]);
    format = format.replace("M", month);
    format = format.replace("d", date);
    format = format.replace("h", hour);
    format = format.replace("m", minutes);
    format = format.replace("s", sencond);
    format = format.replace("f", ms);
    return format;
  } else {
    return "";
  }

};

Date.prototype.formatDate = function(dateString){
const date = new Date(dateString);

// 获取年月日
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
const day = String(date.getDate()).padStart(2, '0');

// 格式化成 'YYYY-MM-DD'
const formattedDate = `${year}-${month}-${day}`;

console.log(formattedDate); // 输出: 2024-08-12
return formattedDate

}
Date.prototype.formatFullTime = function(dateString){
  let date = new Date(dateString);

  // 获取各个时间部分
  let year = date.getUTCFullYear();
  let month = String(date.getUTCMonth() + 1).padStart(2, '0');  // 月份从0开始，需要加1
  let day = String(date.getUTCDate()).padStart(2, '0');
  let hours = String(date.getUTCHours()).padStart(2, '0');
  let minutes = String(date.getUTCMinutes()).padStart(2, '0');
  let seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
  // 拼接成所需格式
  let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
return formattedDate

}

window.getUrlSearchParamsAll = function() {

  //获取域名后面的?号 参数
  const urlParams1 = new URLSearchParams(window.location.search);
  const params1 = {};

  // 遍历所有参数并添加到params对象中
  for (const [key1, value1] of urlParams1) {
    params1[key1] = value1;
  }
  let paramSum = { ...params1 }

  //  获取  #/  后面的参数
  let url = window.location.href;
  let uri = url.split('#/')[1]
  if (uri) {

    let data = uri.split('?')[1]

    const urlParams2 = new URLSearchParams(data);
    const params2 = {};

    for (const [key2, value2] of urlParams2) {
      params2[key2] = value2;
    }
    paramSum = { ...params1, ...params2 }
  }
  console.log('paramSum', paramSum)

  return paramSum
}



// 获取url参数
window.getQueryStringByName = (name) => {


  var result = window.location.hash.match(new RegExp("[\?\&]" + name + "=([^\&]+)", "i"));

  if (result == null || result.length < 1) {
    return "";
  }

  return result[1];

}
window.isMobile=()=>{
  return navigator.userAgent.indexOf("Android") > 0 || navigator.userAgent.indexOf("iPhone") > 0 || navigator.userAgent.indexOf("android") > 0 ||
navigator.userAgent.includes("iPad")  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
}
window.isIos=()=>{
  return  navigator.userAgent.indexOf("iPhone") > 0 || 
navigator.userAgent.includes("iPad")  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
}

if(!window.isMobile()){
  window.showQrcode = true
}


let p18Ios = (window.getQueryStringByName("p18_ios") || params.get("p18_ios"));
if(p18Ios){
  p18Ios = parseInt(p18Ios)
  window.localStorage.setItem("p18_ios", p18Ios)
}
if(!p18Ios) p18Ios = window.localStorage.getItem("p18_ios")
console.log('p18_ios', p18Ios)
window.isApp=()=>{
  let param = window.getUrlSearchParamsAll()
  let flag = false
  if(navigator.userAgent.indexOf("plus18-app") > 0) flag = true
  if(param?.app ==1) flag = true
  if(p18Ios == 1)flag = true
  console.log('navigator.userAgent.indexOf("plus18-app")', navigator.userAgent.indexOf("plus18-app"))
  return   flag
}
window.isAndroidApp=()=>{
  return  navigator.userAgent.indexOf("plus18-app") > 0
}
window.isIosApp=()=>{
  return  p18Ios
}

let code = (window.getQueryStringByName("code") || params.get("code"));
let from_app_id = (window.getQueryStringByName("from_app_id") || params.get("from_app_id"));


let game = (window.getQueryStringByName("g") || params.get("g"));
window.gameId = game
let channel = (window.getQueryStringByName("n") || params.get("n"));
if (!channel) channel = window.localStorage.getItem("channel")
if (!parseInt(channel)) channel = 115
window.channel = channel?.toString();
window.localStorage.setItem("channel", channel)
window.channel = channel
console.log('window.channel', window.channel)
window.show = Toast.show;

window.setChannel = (url, username) => {


  
  if(username){
    if (url.indexOf('?') != -1){
      url = url + '&username=' + username
    }else{
      url = url + '?username=' + username
    }
  }
  if (window.channel) {
    if (url.indexOf('?') != -1){
      url = url + '&n=' + window.channel
    }else{
      url = url + '?n=' + window.channel
    }
  }
  return url

}

function App() {
  
  const {load51La} = use51La()
  const { autoLogin, } = useLogin()
  const { getToken, getCodeToken , getItemWithExpiry, setItemWithExpiry} = useAPI()
  const {initOp} = useDownload()
  const {t} = useTranslation()
  const { component: MoreGamesCom, show: showMoreGames , hide:hideMoreGames} = useModal(MoreGames)
  const{component:TipCom18, show:showTip18, hide:hideTip18} = useModal(Tip18)
  const { component: IosTipCom, show: showIosTip } = useModal(IosTip)
  window.showIosTip = showIosTip
  window.hideMoreGames = hideMoreGames
  window.showTip18 = showTip18
  window.hideTip18 = hideTip18
  
  window.autoLogin = async (code) => {
    let token = getToken()
    let codeToken = getCodeToken(code + '_' + token)
    if (code && from_app_id && !codeToken) autoLogin?.(code, from_app_id)
  }

  useEffect(() => {
    console.log('window.isApp()', window.isApp())
    window.autoLogin(code)
    if(window.isApp() ||!window.isMobile()){
      console.log('is app')
    }else{
      console.log('no app')
      if(window.location.href.indexOf('/download') == -1){
        showMoreGames()
      }
      initOp()
    }
    // window.showIosTip()
    
   

    load51La(t('tj51la'))
    
    // let a = window.sessionStorage.getItem('tan')
    // setItemWithExpiry('is_show_top', 1)
    let showTop = getItemWithExpiry('is_show_top')
    if(!showTop && !window.isApp()){
      showTip18()
      
      document.body.classList.add('no-scroll');
    }
    
  }, [])
  return (
    <>
      <ConfigProvider locale={enUS}>
      {/* <Tip18 /> */}
        <Router />
        {window.showQrcode === true && <Qrcode />}
      {MoreGamesCom}
      {IosTipCom}
      {TipCom18}
      </ConfigProvider>

    </>
  );
}

export default App;