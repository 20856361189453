import "./index.less"
import { Image } from 'antd-mobile'
import { Navigate } from "react-router-dom";



//列表中的单个元素：
const Good = ({ info,  click }) => {
    
    
    
    return <div className="good" onClick={()=>{click?.(info)}} >
        <div>
            <div><Image src={info?.icon}  style={{ borderRadius: "0.08rem", margin: "auto" , width:"0.6rem", height:"0.6rem"}} fit='fill' /></div>
            {/* <div style={{ position: "relative", marginTop: "-0.3rem", marginLeft: "-0.8rem" }}><Image src={info?.icon} width={"0.5rem"} height={"0.5rem"} style={{ borderRadius: "0.08rem", margin: "auto" }} fit='fill' /></div> */}
        </div>
        <div style={{marginTop:"0.12rem"}}>
            <div className="app-name">{info?.name}</div>
            {/* <div className="text-desc">{info?.description}</div> */}
        </div>
    </div>
}

export default Good