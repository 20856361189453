import BaseReducer from './BaseReducer.js'
class ChatInfoRedecer extends BaseReducer {
    constructor(state, action) {
        super(state, action, { chatInfo:''})
    }

    set_chat_info = () => {
        this.state = {...this.state, chatInfo:{...this.payload}}
    }
    
}


export default ChatInfoRedecer