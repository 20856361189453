
import './index.less'
import notice_icon from '../../images/img/notice_d.jpg'

const NoticeItem = ({info, click, create_time})=>{
    return <div onClick={()=>{window.location.href = '#/notice_info/' + info?.id}} style={{display:"flex", justifyContent:"", alignItems:"center", backgroundColor:"#353945-1", padding:"0.05rem", cursor:"pointer"}}>
    <div style={{marginRight:"0.1rem"}}><img src={info?.icon || notice_icon} className='notice-img' /></div>
    <div className="notice-b">
        <div className="notice-tit">{info?.title}</div>
        <div style={{color:"#9f9f9f", textAlign:"left", fontWeight:"400"}}>{create_time}</div>
    </div>
</div>
}

export default NoticeItem