import { useEffect } from "react"
import useAPI from "../../hooks/common/useAPI"
import "./index.less"
import { FloatingBubble, Badge } from 'antd-mobile'
import { useTranslation } from "react-i18next"
const MoreGames = ({ onClose, visible }) => {

    const { request } = useAPI()
    const {t} = useTranslation()
    const downloadApp = async () => {
        if(!window?.download_url){
            let rs = await request('/app/download_url')
            window.download_url = rs?.data
        }

        if (window.isIos()) {
            window.location.href = window?.download_url?.ios_url
        //    if(process.env.REACT_APP_LANG == 'hk') window.showIosTip()
        //     const iframe = document.createElement('iframe');
        //     iframe.style.display = 'none';
        //     iframe.src = window?.download_url?.ios_url;    //这种下载方式 ， 不用设置文件类型
        //     document.body.appendChild(iframe);
        //     setTimeout(() => {
        //       document.body.removeChild(iframe);
        //     }, 5000);
            
        } else {
            window.location.href = window?.download_url?.android_url
        }

    }

    return <>
        {visible ? <>
            <FloatingBubble
                axis='xy'
                magnetic=''
                style={{
                    '--initial-position-bottom': '0.5rem',
                    '--initial-position-right': '0rem',
                    '--edge-distance': '0',
                    //   '--size':'160px',
                    // '--height:':'160px',
                    // '--width:':'160px',
                    '--border-radius': '0%',
                    '--background': 'rgba()'
                }}
                children={
                    <div className="right-b">
                        {/* <div style={{textAlign:"right"}}><img src={close} onClick={() => { closeDownload() }} style={{width:"0.25rem"}} /></div> */}
                        {/* <div onClick={() => { downloadApp() }}> */}
                        <div {... (process.env.REACT_APP_LANG == 'vi'&& {onClick:() => { downloadApp() }})} {... (process.env.REACT_APP_LANG == 'hk' && {id:"openinstall-download"})}>
                            <div  >
                            {/* <img src={require(`../../images/img/download-${process.env.REACT_APP_LANG}.png`)} style={{ width: "1.2rem" }} /> */}
                                {process.env.REACT_APP_LANG == 'hk' && <img src={require(`../../images/img/download-${process.env.REACT_APP_LANG}.png`)} style={{ width: "1.2rem" }} />}
                            </div>

                        </div>
                    </div>
                }
            >
            </FloatingBubble>

        </> : ""}
    </>
}

export default MoreGames