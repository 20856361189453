import Footer from "../Footer"
import Header from "../Header"
import  './index.less'
import Banner from "../../component/Banner"

const FullLaout = ({showHeader = true, showFooter = true, banner = [],  children , childrenTop = "0.65rem", ...props})=>{
    return <div className="full-layout" > 
        {showHeader === true && <Header {...props} />}
        
        <div style={{marginTop:childrenTop}}>
        {banner.length > 0 && <Banner list={banner}/>}
            {children}
        </div>
        {showFooter && <Footer />}
    </div>
}

export default FullLaout