


export default {
    tj51la: "JhMlccKE87vX1HqL",
    dowon: "download-hk",
    qidai: "敬請期待",
    // 公共
    login: '登入',
    register: '註冊',

    home: '首頁',
    user_center: '用戶中心',
    money: "餘額",
    pay: '付款',
    user: '用戶中心',
    login_register: "登入/註冊",

    //登錄 註冊
    login_welcom: '歡迎使用Plus18 Games',
    user_name: '用戶名',
    user_name_placeholder: '請輸入用戶名:6-20個字符',
    pwd: '密碼',
    pwd_placeholder: '請輸入密碼:8~16個字符',
    re_pwd: '確認密碼',
    re_pwd_placeholder: '請再次輸入密碼:8~16個字符',
    no_account: '沒有帳號？',
    had_account: '已有帳號？',

    // 個人中心
    currency: "美元",
    go_recharge: "去充值",
    in_game_recharge: '請在遊戲內充值',
    recharge_log: "充值記錄",
    consume_log: "消費記錄",
    setting: '設置',

    //設置中心
    setting_center: "設置中心",
    change_pwd: "修改密碼",
    old_pwd: "原密碼",
    old_pwd_placeholder: "請輸入舊密碼",
    new_pwd: "新密碼",
    new_pwd_placeholder: "請輸入密碼:8~16個字符",
    re_new_pwd: "再次輸入新密碼",
    re_new_pwd_placeholder: "請輸入密碼:8~16個字符",
    confirm_change: "確認修改",

    ht_recharge: "系統充值",
    recharge: "用戶充值",
    buy_prop: "購買道具",
    bind_phone: "綁定手機贈送",
    loading_more: "加載更多",
    no_data: "暫無數據",

    //tip
    user_name_tip: "請填寫用戶名",
    pwd_tip: "請填寫密碼",
    pwd_tip_fail: "密碼必須是6-20位",
    re_pwd_err: "兩次密碼不一樣",
    login_success: "登入成功",
    register_success: "註冊成功",
    change_pwd_success: "修改密碼成功",
    loading: "加載中",
    please_login: "請登入",
    qr_bg: "qr_bg_hk",
    jjsxpng: "jjsx-hk",

    more_game: '更多',
    jjsx: '即將上線',
    game_list: '遊戲',
    qy: '權益',
    notice_title: '公告訊息',
    down_game: '下載',

    t1: '您已经满18岁了吗？',
    t2: '您即将进入限制级网站，内容包含性、暴力、恐怖，请再次确认您已成年且要继续浏览。若您点选「是」进入网站，即视同已年满18岁或超过您所在地区的成人年龄，您进入此网站并同意遵守',
    t3: '服務條款',
    t4: '隱私協定',
    t5: '是，我已滿18歲',
    t6: '未滿18歲請退出',
}