
import { useEffect } from "react"
import "../../pages/Home/index.less"
import useGoodList from "../../hooks/component/useGetList"
import Good from "../Good"
import { InfiniteScroll,Empty  } from 'antd-mobile'


const GoodList = ({lableTitile = '', typeId = 0, searchKey = ''}) => {
    // const {list, pager, getList, loadMore, hasMore} = useGoodList()

    // const loadPage = async () =>{
    //     await loadMore(typeId, searchKey)
    // }

    
    // useEffect(() => {
    //    getList(1, typeId, searchKey)
    // },[searchKey, typeId]) 
    return <>
                {/* {
                    lableTitile && <div className="bar-nav">
                        <div className="left">{lableTitile}</div>
                    </div>
                } */}
                 {/* <div>
                        {list?.map(info => (
                            <Good info = {info} />
                        ))}
                    </div>
                    <InfiniteScroll style={{width:'100%'}} loadMore={loadPage} hasMore={hasMore} /> */}
{/* 
                {list.length > 0 ? <>
                    <div>
                        {list?.map(info => (
                            <Good info = {info} />
                        ))}
                    </div>
                    <InfiniteScroll style={{width:'100%'}} loadMore={loadPage} hasMore={hasMore} />
                </>: <><div style={{display:"block", marginTop:"1rem"}}><Empty description='no data' /></div></>} */}
                
                
            </>
        
}

export default GoodList