import FullLaout from "../../component/FullLayout"
import Good from "../../component/Good"
import { RightOutline, DownlandOutline } from 'antd-mobile-icons'

import useHomeList from "../../hooks/component/useHomeList"
import { useEffect } from "react"
import BaseBox from "../../component/BaseBox"
import BaseBoxBg from "../../component/BaseBoxBg"
import MarginBox from "../../component/MarginBox"

import BottomFalg from "../../component/BottomFalg"
import NoticeItem from "../../component/NoticeItem"
import TopLeft from "../../component/TopLeft"
import useGoInfo from "../../hooks/common/useGoInfo"
import { useTranslation } from "react-i18next"


const Home =  ()=>{
    const {t} = useTranslation()
     const {list, banner,notice, readyList, getList} = useHomeList()
     const {gotoInfo} = useGoInfo()
     
     useEffect(()=>{
        getList()
     }, [])// eslint-disable-line react-hooks/exhaustive-deps
    return <BaseBoxBg >
         <FullLaout title={''} left={<TopLeft />}
     backArrow={false} banner = {banner} showBottomLine = {false} notice={''} headerBg={true}>
        <BaseBox style={{minHeight:"50vh"}}>
        {list.map((typeList, i)=>(
            typeList?.list?.length > 0 && <div key={i}>
                <div className="bar-nav">
                        <div className="left">
                            {/* <div style={{width:"0.1rem", height:"0.25rem", background:"#3772ff", borderRadius:"0.06rem", marginRight:"0.1rem"}}></div> */}
                                {typeList?.name}
                            </div>
                        <div className="right" onClick={()=> window.location.href = `#/games`}>{t('more_game')}<RightOutline /></div>
                </div>
                <div className="list-info">
                            
                        {typeList?.list?.map((info, i) => (
                            <Good key={i} info = {info} click={gotoInfo} />
                        ))}
                        <div style={{width:"0.8rem"}}></div>
                        <div style={{width:"0.8rem"}}></div>
                        <div style={{width:"0.8rem"}}></div>
                </div>
                
            </div>
           
        ))}

    {readyList?.length > 0 &&<div>
                <div className="bar-nav">
                        <div className="left">
                            {/* <div style={{width:"0.1rem", height:"0.25rem", background:"#3772ff", borderRadius:"0.06rem", marginRight:"0.1rem"}}></div> */}
                            {t('jjsx')}
                            </div>
                        <div className="right" onClick={()=> window.location.href = `#/games`}>{t('more_game')}<RightOutline /></div>
                </div>
                <div className="list-info">
                            
                        {readyList?.map((info, i) => (
                            <Good key={i} info = {info} click={gotoInfo} />
                        ))}
                        <div style={{width:"0.8rem"}}></div>
                        <div style={{width:"0.8rem"}}></div>
                        <div style={{width:"0.8rem"}}></div>
                </div>
                
            </div>}

    {notice?.length > 0 && <div className="bar-nav">
                        <div className="left" >{t('notice_title')}</div>
                        <div className="right" onClick={()=> window.location.href = `#/more_notice`}>{t('more_game')}<RightOutline /></div>

                        
                </div>}
                          {notice?.map((info, i) => (
                            <NoticeItem key={i} info = {info}   create_time={new Date().formatFullTime(info?.create_time)}/>
                        ))}  
                        

        
        </BaseBox>
        <BottomFalg {... (list?.length<1 && {isFiexd:true})}/>
        <MarginBox />
    </FullLaout>
    </BaseBoxBg>
}
export default Home