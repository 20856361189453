import BaseReducer from './BaseReducer.js'
class UserInfoReducer extends BaseReducer {
    constructor(state, action) {
        super(state, action, {})
    }

    set_user_info() {
        this.state = this.payload;
    }
    
}


export default UserInfoReducer