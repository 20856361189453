import FullLaout from "../../component/FullLayout"
import Good from "../../component/Good"
import { RightOutline, DownlandOutline } from 'antd-mobile-icons'
import { NavBar, NoticeBar, Image } from 'antd-mobile'
import useHomeList from "../../hooks/component/useHomeList"
import { useEffect } from "react"
import BaseBox from "../../component/BaseBox"
import BaseBoxBg from "../../component/BaseBoxBg"
import MarginBox from "../../component/MarginBox"
import logo from "../../images/img/logo.png"
import useAPI from "../../hooks/common/useAPI"
import useDownload from "../../hooks/common/useDownload"
import './index.less'
import TopLeft from "../../component/TopLeft"





const GiftApp =  ()=>{

    const {request} = useAPI()
    const {initOp} = useDownload()
    const downloadApp = async () => {
        if(!window?.download_url){
            let rs = await request('/app/download_url')
            console.log('rsrrs', rs)
            window.download_url = rs?.data
        }

        if (window.isIos()) {
            window.location.href = window?.download_url?.ios_url
        //    if(process.env.REACT_APP_LANG == 'hk') window.showIosTip()
        //     const iframe = document.createElement('iframe');
        //     iframe.style.display = 'none';
        //     iframe.src = window?.download_url?.ios_url;    //这种下载方式 ， 不用设置文件类型
        //     document.body.appendChild(iframe);
        //     setTimeout(() => {
        //       document.body.removeChild(iframe);
        //     }, 5000);
            
        } else {
            window.location.href = window?.download_url?.android_url
        }

    }

    useEffect(()=>{
        // initOp('openinstall-download2')
    }, [])
     
    return <BaseBoxBg >
         <FullLaout title={''} left={<TopLeft />}
     backArrow={false} showBottomLine = {false} notice={''} headerBg={true}>
        <div className="bg-box" {... (process.env.REACT_APP_LANG == 'vi'&& {onClick:() => { downloadApp() }})} {... (process.env.REACT_APP_LANG == 'hk' && {id:"openinstall-download2"})}>
            <div></div>
            {/* <div style={{display:"flex",  justifyContent:"center", marginBottom:"0.6rem"}}><img src={`${ window.isIos() ? require('../../images/img/ios.png'):require('../../images/img/and.png')}`} style={{ width: "1.6rem" }} /></div> */}
        </div>
        <MarginBox />
        
    </FullLaout>
    </BaseBoxBg>
}
export default GiftApp