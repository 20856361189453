import { useTranslation } from "react-i18next"
import BaseBox from "../../component/BaseBox"
import FullLaout from "../../component/FullLayout"
import goSvg from "../../images/svg/go.svg"
import pwdSvg from "../../images/svg/pwd.svg"
import userSvg from "../../images/svg/s-user.svg"
import "../User/index.less"

const Setting = ()=>{
    const {t} = useTranslation()
    return <FullLaout title={t('setting_center')} rightIcon={<></>} showFooter = {false}>
        <BaseBox style={{padding:"0.2rem 0.3rem"}}>
        <div style={{fontSize:"0.28rem", color:"#FCFCFD"}}>{t('setting')}</div>
            <div className="meun-list">
                        <div className="meun-b"  onClick={()=>window.location.href='#/change_pwd'}>
                            <div style={{display:"flex"}}>
                                <img src={pwdSvg} className="meun-img" />
                                <div>{t('change_pwd')}</div>
                            </div>
                            <div><img src={goSvg} className="go-img" /></div>
                        </div>
                        
                        {/* <div className="meun-b" onClick={()=>window.location.href='#/setting'}>
                            <div style={{display:"flex"}}>
                                <img src={userSvg} className="meun-img" />
                                <div>修改用戶信息</div>
                            </div>
                            <div><img src={goSvg} className="go-img" /></div>
                        </div> */}
                    </div>
        </BaseBox>
    </FullLaout>
}


export default Setting