const useOpenInstall = () => {

    const onOpInstall = (id) => {
         let packet_no =  'plusx' + window.channel

        var data = window?.OpenInstall?.parseUrlParams();///openinstall.js中提供的工具函数，解析url中的所有查询参数

        let dataAll = window.getUrlSearchParamsAll()
        data = {...data, ...dataAll}
        if(window.gameId){
            data.gameId = window.gameId
        }
        console.log('data:', data)
        console.log('ccnn:', packet_no)
        console.log('id',id)
        new window.OpenInstall({
            /*appKey必选参数，平台为每个应用分配的ID*/
            appKey: 'y05hek',//
            /*直接指定渠道编号，默认通过当前页url中的channelCode参数自动检测渠道编号*/
            channelCode: packet_no,

            onready: function () {
                var m = this, button = document.getElementById(id);
                button.onclick = function () {
                    console.log('id',id)
                    setTimeout(() => {
                        m.wakeupOrInstall();
                    }, 100);
                    return false;
                }
            }
        }, data);
    }

    return {  onOpInstall }
}

export default useOpenInstall