import { AppOutline, SearchOutline, UnorderedListOutline, UserOutline} from 'antd-mobile-icons'
import "./index.less"
import { useLocation, useNavigate } from "react-router-dom"
import gift_n from "../../images/svg/gift-n.svg"
import gift_s from "../../images/svg/gift-s.svg"
import home_n from "../../images/svg/home-no.svg"
import home_s from "../../images/svg/home.svg"
import user_s from "../../images/svg/my.svg"
import user_n from "../../images/svg/my-no.svg"
import yx_w from "../../images/svg/yx-w.svg"
import yx_n from "../../images/svg/yx-n.svg"
import notice_n from "../../images/svg/nitice-n.svg"
import notice_w from "../../images/svg/nitice-w.svg"
// import { TabBar } from "antd-mobile/es/components/tab-bar/tab-bar"
import { TabBar } from 'antd-mobile'
import { useTranslation } from 'react-i18next'

const Footer = ()=>{
    const {t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = location
    const setRouteActive = (value) => {
        navigate(value)
      }

    let tabs = [
        {
        key: '/home',
        title: (active) =>active?<div className='colorRed margin-t'>{t('home')}</div>:<div className='margin-t'>{t('home')}</div>,
        icon: (active) =>active?<img src={home_s} style={{width:"0.25rem"}} />:<img src={home_n} style={{width:"0.25rem"}} />,
        },
        {
          key: '/games',
          title: (active) =>active?<div className='colorRed margin-t'>{t('game_list')}</div>:<div className='margin-t'>{t('game_list')}</div>,
          icon: (active) =>active?<img src={yx_w} style={{width:"0.25rem"}} />:<img src={yx_n} style={{width:"0.25rem"}} />,
        },
        // {
        //   key: '/more_notice',
        //   title: (active) =>active?<div className='colorRed margin-t'>{'最新訊息'}</div>:<div className='margin-t'>{'最新訊息'}</div>,
        //   icon: (active) =>active?<img src={notice_w} style={{width:"0.25rem"}} />:<img src={notice_n} style={{width:"0.25rem"}} />,
        // },
        // {
        //   key: '/hotmovies',
        //   title: (active) =>active?<div className='colorRed margin-t'>{'權益'}</div>:<div className='margin-t'>{'權益'}</div>,
        //   icon: (active) =>active?<img src={gift_s} style={{width:"0.25rem"}} />:<img src={gift_n} style={{width:"0.25rem"}} />,
        // },
        // {
        //   key: '/user',
        //   title: (active) =>active?<div className='colorRed margin-t'>{t('user')}</div>:<div className='margin-t'>{t('user')}</div>,
        //   icon: (active) =>active?<img src={user_s} style={{width:"0.25rem"}} />:<img src={user_n} style={{width:"0.25rem"}} />,
        //   },
        // {
        // key: '/hotmovies',
        // title: (active) =>active?<div className='colorRed margin-t'>{'权益'}</div>:<div className='margin-t'>{'权益'}</div>,
        // icon: (active) =>active?<img src={gift_s} style={{width:"0.25rem"}} />:<img src={gift_n} style={{width:"0.25rem"}} />,
        // },
        // {
        // key: '/user',
        // title: (active) =>active?<div className='colorRed margin-t'>{t('user')}</div>:<div className='margin-t'>{t('user')}</div>,
        // icon: (active) =>active?<img src={user_s} style={{width:"0.25rem"}} />:<img src={user_n} style={{width:"0.25rem"}} />,
        // },
        // {
        // key: '/user',
        // title: '个人中心',
        // icon: <UserOutline />,
        // },
    ]

    if(process.env.REACT_APP_LANG == 'hk'){
      tabs.push(
        {
          key: '/hotmovies',
          title: (active) =>active?<div className='colorRed margin-t'>{t('qy')}</div>:<div className='margin-t'>{t('qy')}</div>,
          icon: (active) =>active?<img src={gift_s} style={{width:"0.25rem"}} />:<img src={gift_n} style={{width:"0.25rem"}} />,
        },
      )
    }

    tabs.push(
      {
        key: '/user',
        title: (active) =>active?<div className='colorRed margin-t'>{t('user')}</div>:<div className='margin-t'>{t('user')}</div>,
        icon: (active) =>active?<img src={user_s} style={{width:"0.25rem"}} />:<img src={user_n} style={{width:"0.25rem"}} />,
        },
    )

    
  return <div className="bottom">
    <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
      {tabs.map(item => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  </div>
}

export default Footer