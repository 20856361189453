const use51La = () => {

    const load51La = (id) => {
        window.LA?.init?.({ id: id, ck: id ,autoTrack:true})
    }
    return { load51La }

}


export default use51La;  