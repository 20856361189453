import { useEffect, useState } from "react"
import useAPI from "./useAPI"
import { useDispatch, useSelector } from "react-redux"

const useUserInfo = () =>{
    const {request , getToken} = useAPI()
    const state = useSelector(t=>t?.userInfo)
    const [userData, setUserData] = useState()
    const dispatch = useDispatch()
    const getUserInfo = async () =>{
        let res = await request('/user/info', {}, 'POST', false)
        if (res) {
            dispatch({type:"set_user_info", payload:res?.data})
        }
        return false
    }

    const getUserData = async ()=>{
        let res = await request('/user/user_data', {}, 'POST', false)
        if (res) {
            if(res?.data?.user_info)dispatch({type:"set_user_info", payload:res?.data.user_info})
            setUserData(res?.data)
        }
        return false
    }

    

    return {state, getUserData, getUserInfo}
}
export default useUserInfo