import { useState } from "react"

//重新封闭通用modal  弹框
 const useModal = (Component, {props: rootProps} = {})=>{
    const [visible, setVisible] = useState()
    const [props, setProps] = useState()

    const show = ()=>{
        setVisible(true)
        setProps(props)
    }
    
    const hide = ()=>{
        setVisible(false)
        setProps(props)
    }

    return {
        component: <Component {...rootProps} {...props} visible = {visible} onClose = {()=>hide()} ref = { rootProps?.ref} />,
        show, hide
    }
 }

 export default useModal