
import { useEffect, useState } from "react"
import { QRCodeSVG } from 'qrcode.react'
import "./index.css"
import { useTranslation } from "react-i18next"
const Qrcode = () => {

    const {t} = useTranslation()
    // const {show, setData:setShow}  = useQrcode()
    const [show, setShow] = useState(true)
    const [qrUrl, setQrUrl] = useState('')
    const hid = () => {

    }

    useEffect(()=>{
        let wb = decodeURIComponent(window.getQueryStringByName("wb"));
        console.log('object   gg', wb)
        let qr_url = window.location.href
        if (wb) {
          let base = wb.substring(6, wb.length - 6)
          if (base && base.length > 0) {
            let name = decodeURIComponent(window.atob(base))
            if(name){
                qr_url = name
            }
          }
        }
        setQrUrl(qr_url)
    }, [])

    return <>
        {/* <div className="big-box" onClick = {()=>setShow()} > */}
        {/* <div style={{ position: "fixed" ,display:`${show === true ? "block" :"none" }`}}> */}
        <div id="qrcode-box" className="box"  >
        {/* <div id="qrcode-box" className="box" style={{ display: "none" }}  > */}
            {/* <div id="qrcode-box" className="box" style={{display:`${show === true ? "block" :"none" }`} } onClick = {()=>setShow(false)}  > */}

            <div className="qr_bg">
                <   div style={{ width: "251px", height: "315px" }}>
                    <div style={{position:"absolute"}}>
                    {/* <img src={process.env.REACT_APP_RES_DOMAIN + '/images/' + t('qr_bg') + '.bnc'} alt="" /> */}
                    <img src={require(`../../images/img/${t('qr_bg')}.png`)} alt="" />
                    
                    </div>
                    <div className="qr_code">
                        <div style={{ zIndex: "9999" }}>
                            {qrUrl && <QRCodeSVG value={qrUrl} size={150} />}
                        </div>

                    </div>
                </div>
            </div>


        </div>
        {/* <div style={{marginBottom:"10px"}}>請使用手機瀏覽器或攝像頭掃碼下載遊戲，勿用微信掃碼</div> */}
        {/* <div className="code-fixed" onClick = {()=>setShow()} ></div> */}
        {/* </div> */}
    </>
}

export default Qrcode