import { Button, Image, Swiper, Toast } from 'antd-mobile'
import Good from '../Good'
import "./index.less"
import useUserInfo from '../../hooks/common/useUserInfo'
import useSDK from '../../hooks/common/useSDK'
const Banner = ({ list }) => {
  const { state: userInfo } = useUserInfo()
  const { openUrl } = useSDK()
  const gotoInfo = (info) => {
    let url = `${window.setChannel(info?.jump_link, userInfo?.username)}`
    openUrl(url)
  }

  const items = list?.map((info, index) => (
    <Swiper.Item key={index} >
      <div className="content">
        {/* info?.cover 'https://p18res4tc.nicelift.cn/bak/1722493017086.gif'  314x383*/}
        <div onClick={()=>{gotoInfo(info)}} ><Image src={info?.cover} width={"3.43rem"}  style={{ borderRadius: "0.08rem", margin: "auto" }} /></div>
      </div>
    </Swiper.Item>
  ))


  return <div>
    <Swiper loop={true} autoplay={true} >{items}</Swiper>
  </div>
}

export default Banner