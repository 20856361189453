import BaseReducer from './BaseReducer.js'
class ProfilesReducer extends BaseReducer {
    constructor(state, action) {
        super(state, action, { ladyList: [], manList: [], ladyPager: { current: 1, total: 0, pageSize: 20 }, manPager: { current: 1, total: 0, pageSize: 20 }, cursor: '' })
    }

    chat_lady_list_init = () => {
        this.state = { ...this.state, ladyList: [...this.payload?.data], ladyPager: this.payload?.pager }
    }
    man_list_init = () => {
        this.state = { ...this.state, manList: [...this.payload?.mans], manPager: this.payload?.pager, cursor: this.payload?.cursor }
    }

    chat_lady_next_list = () => {
        let newList = [...this.state.ladyList, ...this.payload?.data]
        this.state = { ...this.state, ladyList: [...newList], ladyPager: this.payload?.pager }
    }

    chat_man_next_list = () => {
        let newList = [...this.state.manList, ...this.payload?.mans].distinct(function(a,b){return a?.id === b?.id})
        this.state = { ...this.state, manList: [...newList], manPager: this.payload?.pager, cursor: this.payload?.cursor }
    }
    // search_profiles = () =>{
    //     let newList = [...this.state.ladyList, ...this.payload?.data].distinct(function(a,b){return a?.profile_name === b?.profile_name})
    //     this.state = { ...this.state, ladyList: [...newList], ladyPager: this.payload?.pager }
    // }
}


export default ProfilesReducer