import { useState } from "react"
import useAPI from "../common/useAPI"

const useHomeList = () => {
    const { request } = useAPI()
    const [list, setList] = useState([])
    const [banner, setBanner] = useState([])
    const [readyList, setReadyList] = useState([])
    const [notice, setNotice] = useState([])
    const [noticeList, setNoticeList] = useState([])
    const [gameList, setGameList] = useState([])
    const [goodInfo, setGoodInfo] = useState([])
    const [noticeInfo, setNoticeInfo] = useState([])

    const getGoodInfo = async () => {
        // let res = await request('/game/home_list')
        // console.log('res:',res?.data)
        // setList(res?.data?.home_dict_data_dto)
        // setBanner(res?.data?.banner_data_list)

        let i = {
            thumbnail: "https://image14.m1905.cn/uploadfile/2023/1018/thumb_1_640_360_20231018110328749743.jpg",
            icon: "https://image11.m1905.cn/uploadfile/2023/1018/thumb_1_640_360_20231018091147483896.jpg",
            name: "女神使命",
            type_name: "女神使命",
            size: "8896.21Mb",
            paly: "12555人在玩",
            type_str: "卡片",
            rate: "9.0",
            desc: "《英雄聰明》這是一款由閃著公司開發運營的公司開發運營的公司開發運營的遊戲",
        }
        setGoodInfo(i)
    }
    const getList = async () => {
        let res = await request('/game/home_list')

        setBanner(res?.data?.banner)
        setList(res?.data?.list)
        setNotice(res?.data?.notice)
        setReadyList(res?.data?.ready)
    }
    const getMoreList = async () => {
        let res = await request('/game/game_more')
        console.log('res:', res?.data)
        setGameList(res?.data)
    }
    const getNoticeList = async () => {
        let res = await request('/game/notice_list')
        console.log('res:', res?.data)
        setNoticeList(res?.data)
    }
    const getNoticeInfo = async (id) => {
        let res = await request('/game/notice_info?id=' + id)
        console.log('res:', res?.data)
        setNoticeInfo(res?.data)
    }

    return { list, banner, getList, getMoreList, gameList, getGoodInfo, goodInfo,notice,getNoticeList, noticeList ,getNoticeInfo, noticeInfo, readyList}

}

export default useHomeList