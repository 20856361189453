import useOpenInstall from "./useOpenInstall";

const useDownload = () =>{

    const {onOpInstall} = useOpenInstall()

    const loadScript = (url, callback) => {
        callback = typeof callback === 'function' ? callback : function () { };
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onreadystatechange = function () {
            if (this.readyState == "loaded" || this.readyState == "complete") {
                callback();
            }
        }
        script.onload = callback;
        head.appendChild(script);
    }

    const initOp = (id = 'openinstall-download')=>{
        if (process.env.REACT_APP_LANG == 'hk') {
            //加载自己的SDKop  opsdk.JS
            loadScript('https://res.openinstall.com/openinstall-zfnayt.js', function () {
                console.log('loaded op success')
                onOpInstall(id)
                
            });
        }
    }


    return { initOp ,loadScript}
}


export default useDownload