
import { AntOutline, StarFill } from 'antd-mobile-icons'
import './index.less'
import { useTranslation } from 'react-i18next'

const GameItem = ({info, click}) => {
    const{t} = useTranslation()
    return <div  onClick={()=>{click?.(info)}} style={{cursor:"pointer"}}>
        {/* 圖片比例:344x194 https://p18res4tc.nicelift.cn/bak/img-b.jpg 'https://p18res4tc.nicelift.cn/bak/512.jpg' */}
        <div className='img-b'>
            {info?.online == 0 &&<div className='triangle'><img src={require(`../../images/img/${t('jjsxpng')}.png`)} /></div>}
            <img src={info?.cover} className='item-img' />
        </div>
        <div style={{ display: "flex", padding: "0.1rem 0.12rem 0.12rem", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", }}>
                <div><img src={info?.icon} style={{ width: "0.56rem", height: "0.56rem", borderRadius: "0.08rem", objectFit: "cover" }} /></div>
                <div style={{ marginLeft: "0.1rem", width: "1.7rem" }}>
                    <div style={{ fontSize: "0.14rem", fontWeight: "700", color: "#fff", lineHeight: "0.20rem" }}>{info?.name}</div>
                    <div style={{ display: "flex", fontSize: "0.12rem" }}>

                        <div style={{ display: "flex", alignItems: "center", color: "rgb(0,205,186)", fontWeight: "bold" }}> <StarFill /> &nbsp;{(info?.rating||9).toFixed(1)}</div>
                        <span className='des-b'>&nbsp;{info?.description}</span>
                    </div>
                </div>
            </div>
            <div className='down-box'>{t('down_game')}</div>
        </div>
    </div>
}

export default GameItem