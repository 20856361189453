import { useEffect } from "react"
import useHomeList from "../../hooks/component/useHomeList"
import FullLaout from '../../component/FullLayout'
import BaseBox from '../../component/BaseBox'
import { useParams } from "react-router-dom"

const NoticeInfo = ()=>{
    const { noticeInfo, getNoticeInfo } = useHomeList()

    let params = useParams()
    let id = params?.id
    
    useEffect(() => {
        getNoticeInfo(id)
    }, [id])
    return <FullLaout title={'公告訊息'} rightIcon={<></>} showFooter = {false}>
        <BaseBox style={{padding:"0 0.16rem",}} >
         
        <div style={{color:"#fff", fontSize:"0.16rem", fontWeight:"700", textAlign:"center", marginBottom:"0.2rem"}}>{noticeInfo?.title}</div>

        <div style={{color:"#9f9f9f", lineHeight:"0.22rem", textIndent:"0.2rem"}} dangerouslySetInnerHTML={{ __html: noticeInfo?.body }}></div>
        
        </BaseBox>
      
        {/* <BottomFalg  {... (noticeList?.length<10 && {style:{position:"fixed", bottom:"0.2rem", width:"100%", }})}/> */}
    </FullLaout>

}

export default NoticeInfo