
import { NavBar, NoticeBar, Image } from 'antd-mobile'
import logo from "../../images/img/logo.png"
const TopLeft = ()=>{
return <div style={{display:"flex", justifyContent:"start", alignItems:"center"}} onClick={()=>window.location.href = '/'}>
<div> <Image src={logo} style={{ width: "0.3rem", height: "0.3rem", marginRight:"0.08rem" }} /></div>
<div style={{fontSize:"0.16rem", fontWeight:"bold", color:"#FCFCFD",}}>Plus18 Games</div>
</div>
}

export default TopLeft