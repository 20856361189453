const useUtils = ()=>{

    const getUrlSearchParamsAll =() => {

        //获取域名后面的?号 参数
        const urlParams1 = new URLSearchParams(window.location.search);
        const params1 = {};
      
        // 遍历所有参数并添加到params对象中
        for (const [key1, value1] of urlParams1) {
          params1[key1] = value1;
        }
        let paramSum = { ...params1 }
      
        //  获取  #/  后面的参数
        let url = window.location.href;
        let uri = url.split('#/')[1]
        if (uri) {
      
          let data = uri.split('?')[1]
      
          const urlParams2 = new URLSearchParams(data);
          const params2 = {};
      
          for (const [key2, value2] of urlParams2) {
            params2[key2] = value2;
          }
          paramSum = { ...params1, ...params2 }
        }
        console.log('paramSum', paramSum)
      
        return paramSum
      }

      return {getUrlSearchParamsAll}
}
export default useUtils