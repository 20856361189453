import FullLaout from "../../component/FullLayout"
import GoodList from "../../component/GoodList"
import { SearchOutline } from 'antd-mobile-icons'
import { SearchBar  } from 'antd-mobile'
import { useEffect, useState } from "react"
import "../../index.less"
import './index.less'
const Search = () =>{
    const [searchKey, setSearch] = useState()

    return <FullLaout title={'Hot movies'} backArrow={false} showBottomLine = {true} >
            <div className="box-center">
                <SearchBar style={{'--height': '0.4rem','--padding-left': '12px',}} placeholder='输入电影名称' onSearch={val => { setSearch(val) }} clearable onlyShowClearWhenFocus={true} />
                <GoodList searchKey = {searchKey}/>
            </div>
        
        </FullLaout>
}

export default Search