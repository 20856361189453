


const BottomLogo = ()=>{
    return <div style={{display:"flex", position:"absolute", bottom:"0.3rem", right:"0.3rem", color:"#FCFCFD", fontSize:"0.18rem"}}>
        <div><img src={require('../../images/img/logo.png')} style={{width:"0.25rem"}} /></div>
        <div>&nbsp;&nbsp;&nbsp;Plus18 Games</div>
    </div>
}

export default BottomLogo