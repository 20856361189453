import BaseReducer from './BaseReducer.js'
class NotiListReducer extends BaseReducer {
    constructor(state, action) {
        super(state, action, { list: [], clockList: [] })
    }

    init = () => {
        this.state.list = [...this.payload]
    }
    list_pop()
    {
        let listLen = 50//列表保留长度
        if (this.state.list?.length > listLen) {
            let n = this.state.list?.length - listLen
            for (var i = 0; i < n; i++) {
                this.state.list.pop()
            }
        }
    }
    receve = () => {
        let newList = this.state.list.where(t => t?.id != this.payload?.id)
        newList = newList.where(t => t?.dialog_id != this.payload?.id)
        let item = { ...this.payload, time_str: this.getDateDiff(this.payload?.time) }
        newList.unshift(item)
       
        this.state.list = [...newList]

        let newClock = this.state.clockList.where(t => t.dialog_id != this.payload.id)
        this.state.clockList = [...newClock]

        this.list_pop()
    }

    set_notice_readed() {
        let item = this.getItemById(this.payload?.id);
        if (item) {
            item.is_read = 1;
        }
    }

    remove_notice() {
        let newList = this.state.list.where(t => t?.id != this.payload?.id)
        this.state.list = [...newList]
    }

    getItemById(id) {
        return this.state?.list?.where?.(t => t.id === id)?.[0];
    }

    refresh_notice_time() {
        if ([...this.state.list].length > 0) {
            let newList = [...this.state.list].select(t => { t.time_str = this.getDateDiff(t?.time); return t })
            this.state.list = [...newList]
        }
    }
    refresh_clock_time() {
        let clockList = this.state.list.where(t => t?.type === 'message-clock')
        let clen = clockList.length
        if (clen > 0) {
            for (let i = 0; i < clen; i++) {
                let item = clockList[i]
                let flag =  this.getClockTimeStr(item.add_time)
                if(flag === false){
                    item.clock_time = '00:00'
                    item.add_time = -1
                }else{
                    item.clock_time = flag
                }
            }
        }

        let newClock = this.state.clockList.where(t => new Date().getTime() - new Date(t.dateUpdated).getTime() > 300* 1000)
        newClock = newClock.select(t=>{t.add_time = new Date(); return t})
        this.state.list = [...[...this.state.list].where(t=>t?.add_time != -1), ...newClock]

        let leftClock = this.state.clockList.except(newClock)
        this.state.clockList = [...leftClock]
    }

    push_clock_list() {
        let newClock = this.state.clockList.where(t => t.id != this.payload.id)
        this.state.clockList = [...newClock, {...this.payload, dateUpdated:new Date()}]
        this.list_pop()
    }

    

    getClockTimeStr(time) {
        var value =(new Date(time).getTime() + 300 * 1000) -  new Date().getTime() ;
        if(value <= 0) return false

        var secondTime = parseInt(value / 1000); // 秒
        var minuteTime = 0; // 分
        if (secondTime >= 60) {
            minuteTime = parseInt(secondTime / 60);
            secondTime = parseInt(secondTime % 60);
        }
        var secondTimes = (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime));
        var result = "00:" + secondTimes;
        if (minuteTime > 0) {
            var minuteTimes = (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime));
            result =  minuteTimes + ":" + secondTimes;
        }
        return result
    }

    getDateDiff(dateTimeStamp) {
        if (!dateTimeStamp) return 'just now'
        // 时间字符串转时间戳 
        var timestamp = new Date(dateTimeStamp).getTime();

        var minute = 1000 * 60;
        var hour = minute * 60;
        var day = hour * 24;
        var halfamonth = day * 15;
        var month = day * 30;
        var year = day * 365;
        var now = new Date().getTime();
        var diffValue = now - timestamp;
        var result;
        if (diffValue < 0) {
            return 'just now';
        }
        var yearC = diffValue / year;
        var monthC = diffValue / month;
        var weekC = diffValue / (7 * day);
        var dayC = diffValue / day;
        var hourC = diffValue / hour;
        var minC = diffValue / minute;

        if (yearC >= 1) {
            result = "" + parseInt(yearC) + " years ago";
        } else if (monthC >= 1) {
            result = "" + parseInt(monthC) + " mon ago";
        } else if (weekC >= 1) {
            result = "" + parseInt(weekC) + " week ago";
        } else if (dayC >= 1) {
            result = "" + parseInt(dayC) + " day ago";
        } else if (hourC >= 1) {
            result = "" + parseInt(hourC) + " hour ago";
        } else if (minC >= 1) {
            result = "" + parseInt(minC) + " min ago";

        } else if (parseInt(diffValue / 1e3) >= 10) {
            result = "" + parseInt(diffValue / 1e3) + " sec ago";
        } else {
            result = "just now";
        }
        return result
    }
}


export default NotiListReducer