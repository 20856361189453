
export default () => {

    const openUrl = (url) => {
        window.location.href = url;
        // if(window.isAndroidApp()){
        //     window?.gameBridge?.openUrl?.(url);
        // }else{
        //     window.location.href = url;
        // }
    }
    return {  openUrl }
}