import { HashRouter, Routes, Route } from "react-router-dom"
import Home from "./Home"
import GiftApp from "./GiftApp"
import Search from "./Search"
import User from "./User"
import Login from "./Login"
import Register from "./Register"
import Setting from "./Setting"
import ChangePwd from "./ChangePwd"
import RechargeLog from "./RechargeLog"
import ConsumeLog from "./ConsumeLog"
import DownloadApp from "./DownloadApp"
import Services from "./Services"
import Privacy from "./Privacy"
import NoticeMore from "./MoreNotice"
import MoreGames from "./MoreGames"
import NoticeInfo from "./NoticeInfo"
const Router = ()=>{
    return <HashRouter>
        <Routes>
            <Route path = '/' element={<Home/>}>首页</Route>
            <Route path = '/home' element={<Home/>}>首页</Route>
            <Route path = '/download' element={<DownloadApp/>}>首页</Route>
            <Route path = '/hotmovies' element={<GiftApp/>}>热门电影</Route>
            <Route path = '/search' element={<Search/>}>消息</Route>
            <Route path = '/User' element={<User/>}>个人中心</Route>
            <Route path = '/login' element={<Login/>}>登录</Route>
            <Route path = '/register' element={<Register/>}>登录</Route>
            <Route path = '/setting' element={<Setting />}>登录</Route>
            <Route path = '/change_pwd' element={<ChangePwd />}>登录</Route>
            <Route path = '/reg_log' element={<RechargeLog />}>登录</Route>
            <Route path = '/consume_log' element={<ConsumeLog />}>登录</Route>
            <Route path = '/services' element={<Services />}>登录</Route>
            <Route path = '/privacy' element={<Privacy />}>登录</Route>
            <Route path = '/games' element={<MoreGames />}>登录</Route>
            <Route path = '/more_notice' element={<NoticeMore />}>登录</Route>
            <Route path = '/notice_info/:id' element={<NoticeInfo />}>登录</Route>
        </Routes>
    </HashRouter>
}

export default Router