import { useEffect, useState } from "react"
import BaseBox from "../../component/BaseBox"
import FullLaout from "../../component/FullLayout"
import { Tabs ,Button, Space, Input, SpinLoading  } from 'antd-mobile'
import "../User/index.less"
import useLogin from "../../hooks/common/useLogin"
import "./index.less"
import BottomLogo from "../../component/BottomLogo"
import useIsLogin from "../../hooks/common/useIsLogin"
import { useTranslation } from "react-i18next"
const Setting = ()=>{
    const [password, setPassword] = useState('')
    const [newPwd, setNewPwd] = useState('')
    const {isLogin} = useIsLogin()
    const [rePassword, setRePassword] = useState('')
    const { change_pwd } = useLogin()
  const {t} = useTranslation()
    useEffect(()=>{
      isLogin()
  }, [])
    return <FullLaout title={''} rightIcon={<></>} showFooter = {false}>
        <BaseBox style={{padding:"0.2rem 0.3rem"}}>
        <div><img src={require('../../images/img/logo.png')} style={{width:"0.4rem"}} /></div>
          <div style={{fontSize:"0.2rem", fontWeight:"bold", color:"#FCFCFD", marginTop:"0.08rem"}}>{t('change_pwd')}</div>
          <div style={{color:"#B1B5C3", fontSize:"0.14rem", marginTop:"0.3rem"}}>
            
            <div className="put">
                <div style={{marginTop:"0.01rem"}}> {t('old_pwd')}</div>
                <div><input onChange={e => { setPassword(e.target.value) }}  placeholder={t('old_pwd_placeholder')} className="log-put-p" /></div>
                
                <div style={{marginTop:"0.1rem"}}>{t('new_pwd')}</div>
                <div><input onChange={e => { setNewPwd(e.target.value) }} placeholder={t('new_pwd_placeholder')} className="log-put-p" /></div>
                
                <div style={{marginTop:"0.1rem"}}>{t('re_new_pwd')}</div>
                <div><input onChange={e => { setRePassword(e.target.value) }} placeholder={t('re_new_pwd_placeholder')} className="log-put-p" /></div>
            
            
            <Button color='primary' fill='solid' style={{background:"#3772FF", borderRadius:"0.4rem", marginTop:"0.3rem", height:"0.4rem",letterSpacing:"1px"}} block onClick={()=>{change_pwd(password, newPwd, rePassword)}}>{t('confirm_change')}</Button>
            </div>

          </div>
        </BaseBox>
        <BottomLogo />
    </FullLaout>
}


export default Setting