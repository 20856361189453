import BaseReducer from './BaseReducer.js'
class MessageListRedecer extends BaseReducer {
    constructor(state, action) {
        super(state, action, { list: [], pager:{current:1, total:0, pageSize:10} })
    }

    init_message_list = () => {
        let newList = [...this.payload?.list]
        this.state = {...this.state, list:[...newList].select(t=>{t.str_time = this.getDateDiff(t?.dateCreated);return t}), pager:this.payload?.pager}
    }
    chat_receive() {
        let newList = [...this.state.list].where(t=>t?.id != this.payload?.id)
        this.state = { ...this.state, list: [ ...newList,this.payload].select(t=>{t.str_time = this.getDateDiff(t?.dateCreated);return t}) }
    }

    send_messge = ()=>{
        console.log('send mesage', this.payload)
        let newState = this.state.list
        newState.push({...this.payload, str_time:this.getDateDiff(new Date())})
        this.state.list = [...newState]
    }
    chat_load_prev_page() {
        let newList = [...this.payload?.list]
        this.state = { ...this.state, list: [ ...newList ,...this.state.list].select(t=>{t.str_time = this.getDateDiff(t?.dateCreated);return t}), pager: this?.payload?.pager }
    }
    getDateDiff(dateTimeStamp) {
        if(!dateTimeStamp) return 'just now'
        // 时间字符串转时间戳 
        var timestamp = new Date(dateTimeStamp).getTime(); 
   
        var minute = 1000 * 60; 
        var hour = minute * 60; 
        var day = hour * 24; 
        var halfamonth = day * 15; 
        var month = day * 30; 
        var year = day * 365; 
        var now = new Date().getTime(); 
        var diffValue = now - timestamp; 
        var result; 
        if (diffValue < 0) { 
            return 'just now'; 
        } 
        var yearC = diffValue / year; 
        var monthC = diffValue / month; 
        var weekC = diffValue / (7 * day); 
        var dayC = diffValue / day; 
        var hourC = diffValue / hour; 
        var minC = diffValue / minute; 
        
        if (yearC >= 1) { 
            result = "" + parseInt(yearC) + " years ago"; 
        } else if (monthC >= 1) { 
            result = "" + parseInt(monthC) + " mon ago"; 
        } else if (weekC >= 1) { 
            result = "" + parseInt(weekC) + " week ago"; 
        } else if (dayC >= 1) { 
            result = "" + parseInt(dayC) + " day ago"; 
        } else if (hourC >= 1) { 
            result = "" + parseInt(hourC) + " hour ago"; 
        } else if (minC >= 1) { 
            result = "" + parseInt(minC) + " min ago"; 
        
        } else if (parseInt(diffValue / 1e3) >= 10) { 
            result = "" + parseInt(diffValue / 1e3) + " sec ago"; 
        } else { 
            result = "just now"; 
        } 
        return result
     }
}


export default MessageListRedecer