import Header from "../../component/Header"
import { Tabs ,Button, Space, Input, SpinLoading  } from 'antd-mobile'
import { useState } from "react"
import "../../index.less"
import "./index.less"
import useLogin from "../../hooks/common/useLogin"
import FullLaout from "../../component/FullLayout"
import { Navigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const Login = ()=>{
    const [account, setAccount] = useState('')
    const [password, setPassword] = useState('')

    const [rePassword, setRePassword] = useState('')

    const { t } = useTranslation()
    const { login, register } = useLogin()

   
    return <FullLaout title={''} onBack={()=>window.location.href='#/home'} backArrow={true}  childrenTop={"0.1rem"}  showFooter = {false} rightIcon={<></>} >
        <div className="tabs1" title=''  style={{backgroundColor:"",  padding:"0.8rem 0.3rem", width:"3.75rem", boxSizing:"border-box"}}>
        
          <div><img src={require('../../images/img/logo.png')} style={{width:"0.4rem"}} /></div>
          <div style={{fontSize:"0.22rem", fontWeight:"bold", color:"#FCFCFD", marginTop:"0.08rem"}}>{t('login_welcom')}</div>
          <div style={{color:"#B1B5C3", fontSize:"0.14rem", marginTop:"0.3rem"}}>
          <div style={{marginTop:"0.01rem"}}> {t('user_name')}</div>
            <div><input onChange={e => { setAccount(e.target.value) }}  placeholder={t('user_name_placeholder')} className="log-put" /></div>
            
            <div style={{marginTop:"0.1rem"}}>{t('pwd')}</div>
            <div><input onChange={e => { setPassword(e.target.value) }} placeholder={t('pwd_placeholder')} className="log-put" /></div>
            
            
            <Button color='primary' fill='solid' style={{background:"#3772FF", borderRadius:"0.4rem", marginTop:"0.3rem", height:"0.4rem"}} block onClick={()=>{login(account, password)}}>{t('login')}</Button>

            <div style={{fontSize:"", color:"#B1B5C3", textAlign:"center", marginTop:"0.15rem"}}>{t('no_account')} <span onClick={()=>window.location.href='#/register'} style={{fontSize:"", color:"#3772FF", cursor:"pointer"}}>{t('register')}</span></div>
          </div>
      </div>
    </FullLaout>
}

export default Login