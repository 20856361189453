import { useEffect } from 'react'
import BaseBox from '../../component/BaseBox'
import BaseBoxBg from '../../component/BaseBoxBg'
import FullLaout from '../../component/FullLayout'
import GameItem from '../../component/GameItem'
import MarginBox from '../../component/MarginBox'
import TopLeft from '../../component/TopLeft'
import useHomeList from '../../hooks/component/useHomeList'
import './index.less'
import useGoInfo from '../../hooks/common/useGoInfo'
import BottomFalg from '../../component/BottomFalg'

const MoreGames = () => {
    const { gameList, getMoreList } = useHomeList()
    const { gotoInfo } = useGoInfo()
    useEffect(() => {
        getMoreList()
    }, [])
    return <BaseBoxBg >
        <FullLaout title={''} left={<TopLeft />}
            backArrow={false} showBottomLine={false} notice={''} headerBg={true}>
            <BaseBox style={{ display: "flex", flexDirection: "column", gap: "0.2rem", minHeight:"50vh" }} >
                {gameList.map((item, index) => (

                    <GameItem info={item} key={index} click={gotoInfo} />
                ))}
            </BaseBox>
            {/* <BottomFalg {... (gameList?.length<3 && {style:{position:"fixed", bottom:"0.8rem", width:"100%", }})}/> */}
            <MarginBox />
        </FullLaout>
    </BaseBoxBg>
}
export default MoreGames