import BaseReducer from './BaseReducer.js'
class sendAuthRedecer extends BaseReducer {
    constructor(state, action) {
        super(state, action, {messagesLeft:0,lettersLeft:0, giftLeft:0, posting:{}})
    }

    send_auth = () => {
        this.state = {...this.state, ...this.payload}
    }
    
}


export default sendAuthRedecer