
export default () => {
    const dealMoney = (m) => {
        m = m / 100
        let n = 0
        if(!m) return n
        if (process.env.REACT_APP_LANG == 'vi') {
            m = m.toFixed(0)
            n = new Intl.NumberFormat().format(m)
             n = n.replace(/\./g,',')
             console.log('n', n)
        } else {
            n = m.toFixed(2)
        }
        return n
    }
    return { dealMoney }
}