import FullLaout from "../../component/FullLayout"
import Good from "../../component/Good"
import { RightOutline, DownlandOutline } from 'antd-mobile-icons'
import { NavBar, NoticeBar, Image } from 'antd-mobile'
import useHomeList from "../../hooks/component/useHomeList"
import { useEffect } from "react"
import BaseBox from "../../component/BaseBox"
import BaseBoxBg from "../../component/BaseBoxBg"
import MarginBox from "../../component/MarginBox"
import logo from "../../images/img/logo.png"
import useAPI from "../../hooks/common/useAPI"
import useDownload from "../../hooks/common/useDownload"
import './index.less'
import useUtils from "../../hooks/common/useUtils"
import { useTranslation } from "react-i18next"




const Services =  ()=>{
    const{t} = useTranslation()
    const {getUrlSearchParamsAll} = useUtils()
    
    let param = getUrlSearchParamsAll()
    console.log('param', param)
     
    return <FullLaout title={param?.app == 1 ?'':t('t3')}   rightIcon={<></>} showBottomLine = {false} notice={''} showFooter = {false} backArrow={param?.app == 1 ?false:true}>
        <BaseBox >
        {process.env.REACT_APP_LANG == 'hk' ? <>
        <div className="font-c">
<div className="tit">服務條款</div>

<p>歡迎訪問【plus18 games】（以下簡稱“本網站”）。 本網站由「plus18 games」運營。 為保護您的權益，請您在使用本網站前，仔細閱讀以下使用條款：</p>

<div className="hh">1.條款適用</div>

<p>1.1使用本網站提供的服務（以下簡稱“本服務”）前，請您認真閱讀並接受本使用條款。 只有在完全同意這些條款後，您才能使用本服務。 如果本條款與其他附加條件或特別條款有衝突，以附加條件或特別條款為准。</p>
<p>1.2本網站有權隨時修改本使用條款。 您應定期查閱以瞭解最新的條款。 如果您在條款修改後繼續使用本服務，視為您同意接受修改後的條款。</p>
<p>1.3您確認自己在訪問本網站所在的法律管轄區已滿18歲，或已達到能够查看成人內容的法定年齡，並具備履行本條款的能力。 如果您未滿18歲，應立即停止使用本服務。 您也同意您所在的地區允許接收或查看成人內容。</p>
<p className="hh">2.服務描述</p>
<p>2.1本網站根據實際情況提供服務內容，並擁有最終解釋權。</p>
<p>2.2本服務通過互聯網提供網絡資訊服務，您需自行提供設備並承擔相關費用。</p>
<p>2.3本網站將通過電子郵件、網頁消息及其他適當管道與用戶溝通。 通知在發出時視為用戶已收到。</p>
<p>2.4用戶需自行管理帳戶及密碼，本網站不對因協力廠商不當使用帳戶而產生的責任承擔責任。</p>
<p>2.5本網站的用戶資格不可轉讓，用戶不得將其服務許可權授予協力廠商。</p>
<p>2.6如您違反本使用條款，本網站有權不提前通知即限制或取消您的服務資格。 由此產生的損失或費用，本網站不負責任，並有權要求賠償。</p>
<p>2.7本網站保留删除長期不活躍帳戶的權利。 綁定帳戶若一年內無登入記錄，將視為放棄帳戶權利進行封存處理。 未綁定帳戶（訪客）若半年內無登入記錄，也將進行封存處理。 用戶可以申請恢復帳戶，但不保證帳戶的完整性或恢復的可能性。</p>
<p className="hh">3.授權使用</p>
<p>3.1在本條款的約束下，本網站僅授予您有限的、個人用途的、非商業的、不可轉讓的服務使用權。</p>
<p className="hh">4.用戶行為規範</p>
<p>4.1使用本網站服務時，您應遵守以下規則：</p>
<p>（a）遵守適用的法律法規；</p>
<p>（b）遵循所有網路服務相關的協定和規定；</p>
<p>（c）不得將服務用於非法目的；</p>
<p>（d）不使用任何工具或程式干擾網站正常運作；</p>
<p>（e）不得非法訪問或使用本網站的服務器或未授權區域；</p>
<p>（f）不得限制他人使用本服務；</p>
<p>（g）發現帳戶被非法使用或安全性漏洞時，應立即通知本網站；</p>
<p>（h）不進行違反公序良俗或法律的行為；</p>
<p>（i）不違反有關兒童色情、色情、猥褻物品的法律；</p>
<p>（j）不提供虛假資訊；</p>
<p>（k）不違反本網站的其他條款；</p>
<p>（l）不從事欺詐、拖欠支付或其他不正當行為；</p>
<p>（m）其他本網站認為不適當的行為。</p>
<p className="hh">5.知識產權</p>
<p>5.1本網站提供的內容（包括但不限於文字、圖片、圖形、聲音、視頻、商標等）均由本網站或相關權利人所有，並受相關智慧財產權法保護。</p>
<p>5.2未經本網站或相關權利人書面授權，您不得修改、複製、分發或以其他管道使用本網站及其內容。</p>
<p>5.3經授權後，您在使用本網站內容時，必須標明本網站名稱及網址作為來源。</p>
<p className="hh">6.廣告資訊</p>
<p>7.本網站上展示的廣告內容（包括文字、圖片、動畫等），由廣告主提供。 我們不對廣告內容的準確性或可靠性做任何保證，您應自行判斷。</p>
<p className="hh">8.外部連結</p>
<p>9.本網站提供的連結可能指向其他網站。 提供這些連結僅為方便用戶獲取資訊，本網站不對這些網站內容的真實性、完整性或可信度做任何擔保。</p>
<p className="hh">10.賠償責任</p>
<p>如因您違反法律、法規或本條款造成本網站或協力廠商損失，您同意賠償所有相關損失。</p>
<p className="hh">11.擔保聲明</p>
<p>11.1本網站對本服務不提供任何明示或暗示的擔保，包括但不限於商業適用性或特定目的的適用性。</p>
<p>11.2本網站不擔保服務的持續性、安全性、及時性或無錯誤性。</p>
<p>11.3本服務僅供個人使用，您需自行判斷資訊的準確性和適用性。</p>
<p>11.4若您通過本網站與其他用戶或廠商交易，僅與其建立契約關係，本網站不參與解决交易糾紛。</p>
<p className="hh">12.免責條款</p>
<p>12.1本網站對因使用或無法使用本服務所產生的任何損失或損害不承擔責任，即使本網站已知曉可能會發生此類損害。</p>
<p>12.2本網站在以下情况可以暫停或中斷服務，並不承擔責任：</p>
<p>（a）系統維護或陞級；</p>
<p>（b）不可歸責於本網站的原因造成的服務中斷；</p>
<p>（c）天灾或其他不可抗力因素造成的服務中斷；</p>
<p>（d）用戶違反法律或條款的情况。</p>
<p className="hh">13.服務變更與終止</p>
<p>鑒於網路服務的特殊性，本網站有權隨時變更、中斷或終止部分或全部服務。</p>
<p className="hh">14.法律適用</p>
<p>本使用條款的訂立、執行和解釋以及爭議解决均適用本網站指定的法律，並由指定法院管轄。</p>
<p className="hh">15.條款有效性</p>
<p>15.1本條款構成雙方對本服務的完整協定，取代任何先前的承諾或協定。</p>
<p>15.2如本條款中的任何條款無效，其餘條款仍然有效並具有約束力。</p>
<p>15.3本條款的標題僅為方便閱讀，不影響條款的解釋和適用。</p>
<p className="hh">16.聯系資訊</p>
<p>對於本使用條款或本網站的任何問題或建議，請通過郵件發送到我們的郵箱。</p>

        </div>
        </>:<>
        <div className="font-c">
<div className="tit">Điều khoản dịch vụ</div>

<p>Chào mừng bạn truy cập vào 【plus18 games】 (sau đây gọi là "trang web này"). Trang web này được vận hành bởi "plus18 games". Để bảo vệ quyền lợi của bạn, vui lòng đọc kỹ các điều khoản sử dụng sau đây trước khi sử dụng trang web này:</p>

<div className="hh">1. Phạm vi áp dụng</div>

<p>1.1 Trước khi sử dụng các dịch vụ do trang web này cung cấp (sau đây gọi là "dịch vụ"), vui lòng đọc và chấp nhận các điều khoản sử dụng này. Chỉ khi hoàn toàn đồng ý với các điều khoản này, bạn mới có thể sử dụng dịch vụ. Nếu điều khoản này xung đột với các điều kiện bổ sung hoặc điều khoản đặc biệt khác, thì điều kiện bổ sung hoặc điều khoản đặc biệt sẽ được ưu tiên áp dụng.</p>
<p>1.2 Trang web có quyền sửa đổi các điều khoản sử dụng này bất kỳ lúc nào. Bạn nên kiểm tra định kỳ để cập nhật các điều khoản mới nhất. Nếu bạn tiếp tục sử dụng dịch vụ sau khi điều khoản được sửa đổi, điều đó có nghĩa là bạn đồng ý với các điều khoản đã sửa đổi.</p>
<p>1.3 Bạn xác nhận rằng bạn đã đủ 18 tuổi hoặc đã đạt đến độ tuổi hợp pháp để xem nội dung dành cho người lớn tại khu vực pháp lý nơi bạn truy cập trang web. Nếu bạn chưa đủ 18 tuổi, hãy ngừng sử dụng dịch vụ ngay lập tức. Bạn cũng đồng ý rằng khu vực của bạn cho phép nhận hoặc xem nội dung người lớn.</p>

<p className="hh">2. Mô tả dịch vụ</p>
<p>2.1 Trang web này cung cấp nội dung dịch vụ tùy theo tình hình thực tế và có quyền giải thích cuối cùng.</p>
<p>2.2 Dịch vụ này được cung cấp qua internet, bạn cần tự trang bị thiết bị và chịu mọi chi phí liên quan.</p>
<p>2.3 Trang web này sẽ giao tiếp với người dùng thông qua email, thông báo trên trang web hoặc các phương tiện phù hợp khác. Thông báo được xem như đã được nhận bởi người dùng khi được phát đi.</p>
<p>2.4 Người dùng phải tự quản lý tài khoản và mật khẩu của mình, trang web không chịu trách nhiệm về việc sử dụng tài khoản không đúng của bên thứ ba.</p>
<p>2.5 Tư cách người dùng của trang web này không được chuyển nhượng, và người dùng không được phép cấp quyền sử dụng dịch vụ cho bên thứ ba.</p>
<p>2.6 Nếu bạn vi phạm các điều khoản sử dụng này, trang web có quyền hạn chế hoặc hủy bỏ quyền sử dụng của bạn mà không cần thông báo trước. Trang web không chịu trách nhiệm về mọi tổn thất hoặc chi phí phát sinh và có quyền yêu cầu bồi thường.</p>
<p>2.7 Trang web có quyền xóa các tài khoản không hoạt động trong thời gian dài. Tài khoản đã liên kết sẽ bị tạm ngừng nếu không có hoạt động đăng nhập trong vòng một năm. Tài khoản chưa liên kết (tài khoản khách) sẽ bị tạm ngừng nếu không có hoạt động trong vòng sáu tháng. Người dùng có thể yêu cầu khôi phục tài khoản, nhưng không đảm bảo tính toàn vẹn hoặc khả năng khôi phục của tài khoản.</p>

<p className="hh">3. Quyền sử dụng</p>
<p>3.1 Theo các điều khoản này, trang web chỉ cấp cho bạn quyền sử dụng dịch vụ giới hạn, cho mục đích cá nhân, phi thương mại và không được chuyển nhượng.</p>

<p className="hh">4. Quy tắc hành vi của người dùng</p>
<p>4.1 Khi sử dụng dịch vụ của trang web, bạn phải tuân thủ các quy định sau:</p>
<p>(a) Tuân thủ các luật và quy định áp dụng;</p>
<p>(b) Tuân thủ tất cả các thỏa thuận và quy định liên quan đến dịch vụ mạng;</p>
<p>(c) Không sử dụng dịch vụ cho mục đích bất hợp pháp;</p>
<p>(d) Không sử dụng bất kỳ công cụ hoặc chương trình nào can thiệp vào hoạt động bình thường của trang web;</p>
<p>(e) Không truy cập trái phép vào các máy chủ hoặc khu vực không được phép của trang web;</p>
<p>(f) Không hạn chế người khác sử dụng dịch vụ này;</p>
<p>(g) Khi phát hiện tài khoản bị sử dụng trái phép hoặc có lỗ hổng bảo mật, bạn phải thông báo ngay cho trang web;</p>
<p>(h) Không thực hiện các hành vi trái với thuần phong mỹ tục hoặc pháp luật;</p>
<p>(i) Không vi phạm các luật liên quan đến khiêu dâm trẻ em, khiêu dâm, hoặc nội dung đồi trụy;</p>
<p>(j) Không cung cấp thông tin sai sự thật;</p>
<p>(k) Không vi phạm các điều khoản khác của trang web này;</p>
<p>(l) Không thực hiện hành vi gian lận, nợ nần hoặc các hành vi không hợp lệ khác;</p>
<p>(m) Các hành vi khác mà trang web cho là không phù hợp.</p>

<p className="hh">5. Quyền sở hữu trí tuệ</p>
<p>5.1 Nội dung của trang web này (bao gồm nhưng không giới hạn ở văn bản, hình ảnh, đồ họa, âm thanh, video, thương hiệu, v.v.) đều thuộc sở hữu của trang web hoặc chủ sở hữu quyền liên quan và được bảo vệ bởi luật sở hữu trí tuệ.</p>
<p>5.2 Nếu không có sự cho phép bằng văn bản của trang web hoặc chủ sở hữu quyền liên quan, bạn không được chỉnh sửa, sao chép, phân phối hoặc sử dụng nội dung của trang web này theo bất kỳ cách nào khác.</p>
<p>5.3 Sau khi được ủy quyền, khi sử dụng nội dung của trang web này, bạn phải ghi rõ tên và địa chỉ của trang web làm nguồn.</p>

<p className="hh">6. Thông tin quảng cáo</p>
<p>7. Nội dung quảng cáo hiển thị trên trang web này (bao gồm văn bản, hình ảnh, hoạt hình, v.v.) do nhà quảng cáo cung cấp. Chúng tôi không đảm bảo tính chính xác hoặc độ tin cậy của nội dung quảng cáo, bạn phải tự mình đánh giá.</p>

<p className="hh">8. Liên kết bên ngoài</p>
<p>9. Các liên kết mà trang web này cung cấp có thể dẫn đến các trang web khác. Việc cung cấp các liên kết này chỉ nhằm giúp người dùng tiếp cận thông tin, trang web này không đảm bảo tính xác thực, đầy đủ hoặc độ tin cậy của nội dung từ các trang web đó.</p>

<p className="hh">10. Trách nhiệm bồi thường</p>
<p>Nếu bạn vi phạm pháp luật, quy định hoặc các điều khoản này gây thiệt hại cho trang web hoặc bên thứ ba, bạn đồng ý bồi thường toàn bộ thiệt hại liên quan.</p>

<p className="hh">11. Tuyên bố bảo đảm</p>
<p>11.1 Trang web này không cung cấp bất kỳ bảo đảm nào rõ ràng hoặc ngầm định về dịch vụ, bao gồm nhưng không giới hạn ở khả năng sử dụng cho mục đích thương mại hoặc một mục đích cụ thể.</p>
<p>11.2 Trang web không bảo đảm tính liên tục, an toàn, kịp thời hoặc không có lỗi của dịch vụ.</p>
<p>11.3 Dịch vụ chỉ dành cho mục đích cá nhân, bạn phải tự mình đánh giá tính chính xác và khả năng áp dụng của thông tin.</p>
<p>11.4 Nếu bạn giao dịch với người dùng hoặc nhà cung cấp khác thông qua trang web này, mối quan hệ hợp đồng chỉ giữa bạn và bên đó, trang web không tham gia vào việc giải quyết tranh chấp giao dịch.</p>

<p className="hh">12. Điều khoản miễn trừ trách nhiệm</p>
<p>12.1 Trang web không chịu trách nhiệm về bất kỳ tổn thất hoặc thiệt hại nào phát sinh do việc sử dụng hoặc không thể sử dụng dịch vụ, ngay cả khi trang web đã biết trước khả năng xảy ra những thiệt hại này.</p>
<p>12.2 Trang web có thể tạm ngừng hoặc gián đoạn dịch vụ trong các trường hợp sau và không chịu trách nhiệm:</p>
<p>(a) Bảo trì hoặc nâng cấp hệ thống;</p>
<p>(b) Nguyên nhân không thuộc trách nhiệm của trang web dẫn đến dịch vụ bị gián đoạn;</p>
<p>(c) Thảm họa tự nhiên hoặc các yếu tố bất khả kháng khác gây gián đoạn dịch vụ;</p>
<p>(d) Người dùng vi phạm pháp luật hoặc điều khoản.</p>

<p className="hh">13. Thay đổi và chấm dứt dịch vụ</p>
<p>Xét đến tính đặc thù của dịch vụ internet, trang web có quyền thay đổi, gián đoạn hoặc chấm dứt một phần hoặc toàn bộ dịch vụ bất kỳ lúc nào.</p>

<p className="hh">14. Luật áp dụng</p>
<p>Việc thành lập, thực hiện và giải thích điều khoản sử dụng này cũng như giải quyết tranh chấp sẽ được áp dụng theo luật do trang web chỉ định và được giải quyết bởi tòa án được chỉ định.</p>

<p className="hh">15. Hiệu lực điều khoản</p>
<p>15.1 Điều khoản này tạo thành thỏa thuận hoàn chỉnh giữa hai bên về dịch vụ và thay thế mọi cam kết hoặc thỏa thuận trước đó.</p>
<p>15.2 Nếu bất kỳ điều khoản nào trong điều khoản này vô hiệu, các điều khoản còn lại vẫn có hiệu lực và ràng buộc.</p>
<p>15.3 Tiêu đề của các điều khoản chỉ mang tính tiện ích đọc hiểu và không ảnh hưởng đến việc diễn giải và áp dụng các điều khoản.</p>

<p className="hh">16. Thông tin liên hệ</p>
<p>Nếu có bất kỳ câu hỏi hoặc đề xuất nào về điều khoản sử dụng này hoặc về trang web, vui lòng gửi email đến hộp thư của chúng tôi.</p>

</div>

        </>}
        
        </BaseBox>
        
    </FullLaout>
}
export default Services