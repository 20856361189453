import { useTranslation } from "react-i18next"
import "./index.less"

const BottomFalg = ({style, isFiexd})=>{
    const{t} = useTranslation()
    // return <div style={{color:"#9d9d9d", fontSize:"0.12rem", borderTop:"#4d4d4d 0px solid", paddingTop:"0.3rem", ... style}} id="bottom-flag">
        return <div style={{... style}}  className={`bottom-flag ${isFiexd? 'is-fiexd':''}`}>
        <div style={{textAlign:"center"}}><span onClick={()=>window.location.href='#/services'}>{t('t3')}</span> | <span onClick={()=>window.location.href='#/privacy'}>{t('t4')}</span></div>

        <div className="copyri">Copyright © Plus18 Games. All rights reserved.</div>
    </div>
}

export default BottomFalg