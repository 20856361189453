import BaseReducer from './BaseReducer.js'
class ChatListRedecer extends BaseReducer {
    constructor(state, action) {
        super(state, action, { list: [], pager:{current:1, total:0, pageSize:20}, loading:true })
    }

    init_chat_list = () => {
        let newList = [...this.payload?.data].select(t=>{t.time_str = this.getDateDiff(t.dateUpdated);return t})
        this.state = {...this.state, list:newList, pager:this.payload?.pager}
    }


    chat_list_nex_page = () => {
        let newList = [...this.state.list, ...this.payload?.data].distinct(function(a,b){return a?.dialog_id === b?.dialog_id})
         newList = [...newList].select(t=>{t.time_str = this.getDateDiff(t.dateUpdated);return t})
        this.state = {...this.state, list:[...newList], pager:this.payload?.pager}
    }
    chat_list_receve = () =>{

        let newList = this.state.list.where(t => t?.dialog_id != this.payload?.dialog_id)
        let item = {...this.payload, is_read:0, time_str:this.getDateDiff(this.payload?.dateUpdated)}

        newList.unshift(item)
        this.state.list = [...newList]
    }

    send_update_chat_list = () =>{
        console.log('send update', this.payload)
        let newList = this.state.list.where(t => t?.dialog_id != this.payload?.dialog_id)
        let item = {...this.payload, time_str:this.getDateDiff(this.payload?.dateUpdated)}

        newList.unshift(item)
        this.state.list = [...newList]
    }
    
    set_chat_readed() {
        let item = this.getChatItemById(this.payload?.id);
        if (item) {
            item.is_read = 1;
        }
        console.log('object, item', item)
    }

    getChatItemById(dialog_id) {

        return this.state?.list?.where?.(t => t.dialog_id === dialog_id)?.[0];
    }

    timeCheck(time){
        return new Date(time).format('HH:mm:ss')
    }

    refresh_seesion_time(){
        let newList = [...this.state.list].select(t=>{t.time_str = this.getDateDiff(t.dateUpdated);return t})
        this.state.list = [...newList]
    }

    getDateDiff(dateTimeStamp) {
     // 时间字符串转时间戳 
     var timestamp = new Date(dateTimeStamp).getTime(); 

     var minute = 1000 * 60; 
     var hour = minute * 60; 
     var day = hour * 24; 
     var halfamonth = day * 15; 
     var month = day * 30; 
     var year = day * 365; 
     var now = new Date().getTime(); 
     var diffValue = now - timestamp; 
     var result; 
     if (diffValue < 0) { 
         return 'just now'; 
     } 
     var yearC = diffValue / year; 
     var monthC = diffValue / month; 
     var weekC = diffValue / (7 * day); 
     var dayC = diffValue / day; 
     var hourC = diffValue / hour; 
     var minC = diffValue / minute; 
     
     if (yearC >= 1) { 
         result = "" + parseInt(yearC) + " years ago"; 
     } else if (monthC >= 1) { 
         result = "" + parseInt(monthC) + " mon ago"; 
     } else if (weekC >= 1) { 
         result = "" + parseInt(weekC) + " week ago"; 
     } else if (dayC >= 1) { 
         result = "" + parseInt(dayC) + " day ago"; 
     } else if (hourC >= 1) { 
         result = "" + parseInt(hourC) + " hour ago"; 
     } else if (minC >= 1) { 
         result = "" + parseInt(minC) + " min ago"; 
     
     } else if (parseInt(diffValue / 1e3) >= 10) { 
         result = "" + parseInt(diffValue / 1e3) + " sec ago"; 
     } else { 
         result = "just now"; 
     } 
     return result
  }

}


export default ChatListRedecer