class BaseReducer {
    state;
    /**
     * @type {{type:string,payload:any}}
     */
    action;




    constructor(state, action, defaultState) {
     
        this.state = { ...defaultState, ...state };
        this.action = action;
    }

    get type() {
        return this.action?.type;
    }
    get payload() {
        return this.action?.payload;
    }

    /**
     * @returns {any} newState
     */
    execute() {
        const func = this[this.type]?.bind?.(this);
        func?.();
        return this.state;

    }


}

export default BaseReducer;