// import reducers from "../reducers"
import NotiListReducer from "../reducers/NotiListReducer"
import ChatListRedecer from "../reducers/ChatListRedecer"
import ChatInfoRedecer from "../reducers/ChatInfoRedecer"
import MessageListRedecer from "../reducers/MessageListRedecer"
import UserInfoReducer from "../reducers/UserInfoReducer"
import ProfilesReducer from "../reducers/ProfilesReducer"
import sendAuthRedecer from "../reducers/sendAuthRedecer"
import {legacy_createStore as createStore, combineReducers} from "redux"

const BuildReducer = (Cls) =>{
    return (state, action) =>{
        let rs = new Cls(state, action).execute()
        return rs;
    }
}



const reducers = combineReducers({
    sendAuth:BuildReducer(sendAuthRedecer),
    profiles:BuildReducer(ProfilesReducer),
    userInfo:BuildReducer(UserInfoReducer),
    message:BuildReducer(MessageListRedecer),
    chatInfo:BuildReducer(ChatInfoRedecer),
    notice:BuildReducer(NotiListReducer),
    chatList:BuildReducer(ChatListRedecer)
})



export default createStore(reducers)