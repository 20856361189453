


export default {
    tj51la:"JhMlkW0FyLDLOu3e",
    dowon:"download-vi",
    qidai:"Xin vui lòng chờ đợi",
    // 公共
    login:'Đăng nhập',
    register:'Đăng ký',

    home:'Trang chủ',
    user_center:'Trung tâm người dùng',
    money:"Số dư",
    pay:'Thanh toán',
    user:'Người dùng',
    login_register:"Đăng nhập/Đăng ký",

    //登錄 註冊
    login_welcom:'Chào mừng bạn đến với Plus18 Games',
    user_name:'Tên đăng nhập',
    user_name_placeholder:'Tên đăng nhập (6–20 ký tự)',
    pwd:'Mật khẩu',
    pwd_placeholder:'Mật khẩu (6–20 ký tự)',
    re_pwd:'Xác nhận mật khẩu',
    re_pwd_placeholder:'Mật khẩu (6–20 ký tự)',
    no_account:'Chưa có tài khoản?',
    had_account:'Đã có tài khoản?',

    // 個人中心
    currency:"",
    go_recharge:"nạp tiền",
    in_game_recharge:'Vui lòng nạp tiền trong game',
    recharge_log:"Hồ sơ nạp tiền",
    consume_log:"Hồ sơ tiêu dùng",
    setting:'Cài đặt',

    //設置中心
    setting_center:"Trung tâm cài đặt",
    change_pwd:"Đổi mật khẩu",
    old_pwd:"Mật khẩu gốc",
    old_pwd_placeholder:"Mật khẩu gốc",
    new_pwd:"Mật khẩu mới",
    new_pwd_placeholder:"Mật khẩu (6–20 ký tự)",
    re_new_pwd:"Nhập lại mật khẩu mới",
    re_new_pwd_placeholder:"Mật khẩu (6–20 ký tự)",
    confirm_change:"Xác nhận thay đổi",

    ht_recharge:"Nạp tiền hệ thống",
    recharge:"Nạp tiền người dùng",
    buy_prop:"Mua đạo cụ",
    bind_phone:"Liên kết điện thoại",
    loading_more:"Tải thêm",
    no_data:"Không có dữ liệu",

    //tip
    user_name_tip:"Tên người dùng không được để trống",
    pwd_tip:"Mật khẩu không được để trống",
    pwd_tip_fail:"Mật khẩu phải từ 6–20 ký tự",
    re_pwd_err:"Mật khẩu nhập lại không khớp",
    login_success:"Đăng nhập thất bại",
    register_success:"Đăng ký thành công",
    change_pwd_success:"Đổi mật khẩu thành công",
    loading:"Đang tải",
    please_login:"Vui lòng đăng nhập lại",
    qr_bg:"qr_bg_vi",
    jjsxpng: "jjsx-vn",

    more_game: 'Thêm nữa',
    jjsx: 'Sắp ra mắt',
    game_list: 'Trò chơi',
    qy: 'Quyền lợi',
    notice_title: 'Thông báo',
    down_game: 'Tải',

    t1: 'Bạn đã đủ 18 tuổi chưa?',
    t2: 'Bạn sắp truy cập vào một trang web giới hạn độ tuổi, có nội dung chứa yếu tố tình dục, bạo lực, kinh dị. Vui lòng xác nhận lại rằng bạn đã trưởng thành và muốn tiếp tục truy cập. Nếu bạn chọn "Có" để vào trang web, điều này đồng nghĩa với việc bạn đã đủ 18 tuổi hoặc lớn hơn độ tuổi trưởng thành tại khu vực của bạn, và bạn đồng ý tuân thủ',
    t3: 'Điều khoản dịch vụ',
    t4: 'Chính sách quyền riêng tư',
    t5: 'Có, tôi đã đủ 18 tuổi',
    t6: 'Nếu chưa đủ 18 tuổi, vui lòng thoát ra.',
}