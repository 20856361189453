import { useDispatch } from "react-redux"
import useAPI from "./useAPI"
import { useTranslation } from "react-i18next"



const useLogin = () => {
    const {t} = useTranslation()
    const { request, setToken,  setCodeToken } = useAPI()

    const dispatch = useDispatch()

    const checLoginkData = (account, password) => {
        if (account.length === 0) {
            window.show({
                icon: 'fail',
                content: t('user_name_tip'),
            })
            return false
        }
        if (password.length === 0) {
            window.show({
                icon: 'fail',
                content: t('pwd_tip'),
            })
            return false
        }
        if (password.length < 6 ) {
            window.show({
                icon: 'fail',
                content: t('pwd_tip_fail'),
            })
            return false
        }

        return true
    }
    const checkRegisterData = (account, password, rePassword) => {
        if (account.length === 0) {
            window.show({
                icon: 'fail',
                content: t('user_name_tip'),
            })
            return false
        }
        
        if (password.length === 0) {
            window.show({
                icon: 'fail',
                content: t('pwd_tip'),
            })
            return false
        }
        if (rePassword.length === 0 || rePassword !== password) {
            window.show({
                icon: 'fail',
                content: t('re_pwd_err'),
            })
            return false
        }

        if (password.length < 6 || rePassword.length < 6 ) {
            window.show({
                icon: 'fail',
                content: t('pwd_tip_fail'),
            })
            return false
        }

        return true
    }
    const checkChangePwdData = (password,newPassword, rePassword) => {
        
        if (password.length === 0) {
            window.show({
                icon: 'fail',
                content: t('pwd_tip'),
            })
            return false
        }
        
        if (rePassword.length === 0 || rePassword !== newPassword) {
            window.show({
                icon: 'fail',
                content: t('re_pwd_err'),
            })
            return false
        }
        if (password.length < 6 || newPassword.length < 6 ) {
            window.show({
                icon: 'fail',
                content: t('pwd_tip_fail'),
            })
            return false
        }

        return true
    }


    const autoLogin = async (code, from_app_id) => {

        let params = {
            loading: true,
            des_token:code,
            from_app_id,
            type: 1
        }
        let res = await request("/user/login", params, 'POST', false)
        // let res = {data:{username:"小李飛刀", amount:"66w", token:"fewerwewerwe"}}
        if (res?.result === true) {
            //登录成功逻辑
            dispatch({ type: "set_user_info", payload: res?.data })
            setToken(res?.data?.token);
            setCodeToken(code + '_' + res?.data?.token, res?.data?.token)
        }
    }
    const login = async (account, password) => {

        if (checLoginkData(account, password)) {
            let is_app = window.isApp() ? 1:0
            let params = {
                loading: true,
                username: account,
                is_app,
                password: password
            }
            let res = await request("/user/login", params, 'POST')
            if (res?.result === true) {
                //登录成功逻辑
                dispatch({ type: "set_user_info", payload: res?.data })
                setToken(res?.data?.token);
                window.show({
                    icon: 'success',
                    content: t('login_success'),
                    duration: '1000',
                    afterClose: () => {
                        window.location.href = '#/'
                    }
                })

            }
        }
    }

    const register = async (account, password, rePassword) => {
        if (checkRegisterData(account, password, rePassword)) {

            let is_app = window.isApp() ? 1:0
            let n = window.channel || 208
            let params = {
                loading: true,
                username: account,
                password: password,
                rePassword,
                is_app,
                n : n
            }
            let res = await request("/user/register", params, 'POST')
            console.log('res', res)
            if (res?.result === true) {
                dispatch({ type: "set_user_info", payload: res?.data })
                setToken(res?.data?.token);
                //登录成功逻辑
                window.show({
                    icon: 'success',
                    content: t('register_success'),
                    duration: '1000',
                    afterClose: () => {

                        window.location.href = '#/'
                    }
                })
            } 
        }
    }
    const change_pwd = async (password,newPassword, rePassword) => {
        if (checkChangePwdData(password, newPassword, rePassword)) {
            let params = {
                loading: true,
                old_password: password,
                new_password:newPassword
            }
            let res = await request("/user/change_password", params, 'POST')
            console.log('res222', res)
            if (res?.result === true) {
                // dispatch({ type: "set_user_info", payload: res?.data })
                // setToken(res?.data?.token);
                //登录成功逻辑
                window.show({
                    icon: 'success',
                    content: t('change_pwd_success'),
                    duration: '1000',
                    afterClose: () => {
                        window.location.href = '#/user'
                    }
                })
            }
        }
    }

    const logout = async () => {
        let res = await request("/api/logout", {}, "POST")

        setToken('')
    }
    return { login, register, logout, autoLogin, change_pwd}
}
export default useLogin