import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationZH from './lang/zh-HK';
import translationEN from './lang/en';
import translationVI from './lang/vi';
import translationIN from './lang/in';
const resources = {
    en: {
      translation: translationEN
    },
    zh: {
      translation: translationZH
    },
    vi:{
        translation: translationVI
    },
    in:{
        translation: translationIN
    }
  };
  let lang = process.env.REACT_APP_LANG
  if(lang == 'hk') lang = 'zh'
i18n
  // .use(LanguageDetector)  // 添加语言检测插件,更改浏览器语言后，需要清除缓存
  .use(initReactI18next)  // 通过此插件传递i18next到react-i18next
  .init({
    debugger:true,
    resources,
    
    // 指定默认语言
    fallbackLng: lang,
    // 设置i18next选项
    // detection: {
    //   // 设置检测浏览器语言的选项
    //   order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    //   caches: ['localStorage', 'cookie'],
    //   cookieMinutes: 10,
    //   cookieDomain: 'myDomain'
    // },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
