import { useTranslation } from "react-i18next";
import useSDK from "../../hooks/common/useSDK";
import useUserInfo from "../../hooks/common/useUserInfo";
const useGoInfo = ()=>{
    const {t} = useTranslation()
    const { state: userInfo } = useUserInfo()
    const {openUrl} = useSDK()
    const gotoInfo = (info)=>{
        // console.log('info' , info)
        if(info?.online == 1){
            let url  = `${window.setChannel(info?.link, userInfo?.username)}`
            openUrl(url)
        }else {
            window.show({
                // icon: 'fail',
                content: <div style={{wordBreak:"break-word"}}>{t('qidai')}</div>,
            })
        }
    }

    

    return {gotoInfo}
}

export default useGoInfo