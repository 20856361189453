import { useEffect } from "react"
import FullLaout from "../../component/FullLayout"
import useUserInfo from "../../hooks/common/useUserInfo"
import './index.less'
import useLogin from "../../hooks/common/useLogin"
import { Image } from 'antd-mobile'
import avatar from "../../images/img/logo.png"
import { Modal } from 'antd-mobile'
import BaseBoxBg from "../../component/BaseBoxBg"
import BaseBox from "../../component/BaseBox"
import goSvg from "../../images/svg/go.svg"
import rechargeSvg from "../../images/svg/recharge.svg"
import { useTranslation } from "react-i18next"
import useMoneyDeal from "../../hooks/common/useMoneyDeal"
import BottomFalg from "../../component/BottomFalg"
import MarginBox from "../../component/MarginBox"
const User = () => {
    const { state: userInfo, getUserData } = useUserInfo()
    const { t } = useTranslation()
    const { dealMoney } = useMoneyDeal()
    const recharge = () => {
        window.show({
            // icon: 'fail',
            content: t('in_game_recharge'),
        })
    }

    useEffect(() => {
        getUserData()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    return <BaseBoxBg>
        <FullLaout backArrow={false} showHeader={false} showBottomLine={false} childrenTop="0rem" style={{ backgroundColor: "" }} barStyle={{ "--height": "30px" }}
            rightIcon={<></>}
        >
                <BaseBox style={{ padding: "0 0.3rem" }}>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        {/* 头像区域 */}
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "0.2rem 0.1rem", width: "100%", alignItems: "center" }}>
                            {userInfo?.username ? <div style={{ fontSize: "0.18rem", fontWeight: "bold", color: "#fff" }}>{userInfo?.username}</div> : <div onClick={() => window.location.href = '#/login'} style={{ fontSize: "0.18rem", fontWeight: "bold", color: "#fff" ,cursor:"pointer"}}>{t('login_register')}</div>}


                            <Image src={avatar} style={{ width: "0.4rem", borderRadius: "0.4rem", }} />
                        </div>

                        <div className="yue">
                            <div style={{ fontSize: "0.12rem", color: "#fde1f0", textAlign: "left" }}>{t('money')}<span style={{ fontSize: "0.23rem", fontWeight: "bold" }}> {dealMoney(userInfo?.money)}</span>&nbsp;{t('currency')}</div>
                            <div className="reg_bt" onClick={recharge}>{t('go_recharge')}&nbsp;&nbsp;</div>
                        </div>


                        <div className="meun-list">
                            <div className="meun-b" onClick={() => window.location.href = '#/reg_log'}>
                                <div style={{ display: "flex" }}>
                                    <img src={rechargeSvg} className="meun-img" />
                                    <div>{t('recharge_log')}</div>
                                </div>
                                <div><img src={goSvg} className="go-img" /></div>
                            </div>
                            <div className="meun-b" onClick={() => window.location.href = '#/consume_log'}>
                                <div style={{ display: "flex" }}>
                                    <img src={require('../../images/home/user_menu_record@2x.png')} className="meun-img" />
                                    <div>{t('consume_log')}</div>
                                </div>
                                <div><img src={goSvg} className="go-img" /></div>
                            </div>
                            {/* <div className="meun-b">
                            <div style={{display:"flex"}}>
                                <img src={require('../../images/home/user_menu_cs@2x.png')} className="meun-img" />
                                <div>聯繫客服</div>
                            </div>
                            <div><img src={goSvg} className="go-img" /></div>
                        </div> */}
                            {/* <div className="meun-b">
                            <div style={{display:"flex"}}>
                                <img src={require('../../images/home/user_menu_help@2x.png')} className="meun-img" />
                                <div>問題反饋</div>
                            </div>
                            <div><img src={goSvg} className="go-img" /></div>
                        </div> */}
                            <div className="meun-b" onClick={() => window.location.href = '#/setting'}>
                                <div style={{ display: "flex" }}>
                                    <img src={require('../../images/home/user_menu_setting@2x.png')} className="meun-img" />
                                    <div>{t('setting')}</div>
                                </div>
                                <div><img src={goSvg} className="go-img" /></div>
                            </div>
                            {/* <div className="meun-b">
                            <div style={{display:"flex"}}>
                                <img src={require('../../images/home/user_menu_more@2x.png')} className="meun-img" />
                                <div>更多遊戲</div>
                            </div>
                            <div><img src={goSvg} className="go-img" /></div>
                        </div> */}
                        </div>
                    </div>
                </BaseBox>
                <BottomFalg isFiexd={true} />
            <MarginBox />
        </FullLaout>
    </BaseBoxBg>
}

export default User