import FullLaout from "../../component/FullLayout"
import Good from "../../component/Good"
import { RightOutline, DownlandOutline } from 'antd-mobile-icons'
import { NavBar, NoticeBar, Image } from 'antd-mobile'
import useHomeList from "../../hooks/component/useHomeList"
import { useEffect } from "react"
import BaseBox from "../../component/BaseBox"
import BaseBoxBg from "../../component/BaseBoxBg"
import MarginBox from "../../component/MarginBox"
import logo from "../../images/img/logo.png"
import useAPI from "../../hooks/common/useAPI"
import useDownload from "../../hooks/common/useDownload"
import './index.less'
import useUtils from "../../hooks/common/useUtils"
import { useTranslation } from "react-i18next"





const Privacy =  ()=>{
    const {t} = useTranslation()
    const {getUrlSearchParamsAll} = useUtils()
    
    let param = getUrlSearchParamsAll()
     
    return <FullLaout title={param?.app==1 ? '':t('t4')}   rightIcon={<></>} showBottomLine = {false} notice={''} showFooter = {false} backArrow={param?.app == 1 ?false:true}>
        <BaseBox >
        {process.env.REACT_APP_LANG == 'hk' ? <>
        <div className="font-c">
            
            <div className="tit">隱私協定</div>
    <p>歡迎使用【plus18 games】（以下簡稱“我們”或“本平臺”）。 我們重視用戶的隱私保護，並致力於保護您的個人資訊安全。 本隱私協議旨在向您說明我們如何收集、使用和保護您的資訊。</p>
    <p className="tit">1.我們收集的資訊</p>
    <p>我們在您使用本平臺時，不會收集任何個人相關的數據。 我們僅會收集設備相關的數據，以便提升用戶體驗和優化平臺功能。 具體來說，我們可能會收集以下類型的數據：</p>
    <p>-設備類型（如型號、作業系統）</p>
    <p>- IP地址</p>
    <p>-瀏覽器類型</p>
    <p>-訪問時間和日期</p>
    <p>-使用數據（如使用時長、功能訪問情况）</p>
    <p className="tit">2.資訊的使用</p>
    <p>我們收集的設備相關資料將用於以下目的：</p>
    <p>-提升平臺效能和用戶體驗</p>
    <p>-監測和分析平臺的使用情况</p>
    <p>-識別和修復平臺中的問題</p>
    <p>-開發新功能和服務</p>
    <p className="tit">3.資訊的共亯</p>
    <p>我們不會將您的資訊出售、交易或以其他管道提供給協力廠商。 我們僅在以下情况下共亯您的資訊：</p>
    <p>-遵守法律法規要求</p>
    <p>-保護和維護我們的權利和財產</p>
    <p>-防止或調查可能存在的違法行為或安全問題</p>
    <p className="tit">4.資訊的安全</p>
    <p>我們採取合理的科技和管理措施保護您的資訊安全，防止未經授權的訪問、使用、修改或洩露。</p>
    <p>5.兒童隱私</p>
    <p>我們的平臺不面向13歲以下的兒童。 如果我們發現無意中收集了13歲以下兒童的個人資訊，我們將採取措施儘快删除這些資訊。</p>
    <p className="tit">6.隱私協定的變更</p>
    <p>我們可能會不時更新本隱私協定。 我們將在平臺上發佈新版本，並更新生效日期。 請定期查看本協定，以瞭解我們的資訊保護措施。</p>
    <p className="tit">7.聯繫我們</p>
    <p>如果您對本隱私協定有任何疑問或意見，請通過郵件發送到我們的郵箱</p>
    </div>
        </>:<>
        <div className="font-c">
            
            <div className="tit">Chính sách quyền riêng tư</div>
            <p>Chào mừng bạn sử dụng 【plus18 games】 (sau đây gọi là "chúng tôi" hoặc "nền tảng"). Chúng tôi coi trọng việc bảo vệ quyền riêng tư của người dùng và cam kết bảo vệ an toàn thông tin cá nhân của bạn. Chính sách quyền riêng tư này nhằm giải thích cách chúng tôi thu thập, sử dụng và bảo vệ thông tin của bạn.</p>
            <p className="tit">1. Thông tin chúng tôi thu thập</p>
            <p>Khi bạn sử dụng nền tảng, chúng tôi sẽ không thu thập bất kỳ dữ liệu cá nhân nào liên quan đến bạn. Chúng tôi chỉ thu thập các dữ liệu liên quan đến thiết bị để cải thiện trải nghiệm người dùng và tối ưu hóa tính năng của nền tảng. Cụ thể, chúng tôi có thể thu thập các loại dữ liệu sau:</p>
            <p>- Loại thiết bị (như model, hệ điều hành)</p>
            <p>- Địa chỉ IP</p>
            <p>- Loại trình duyệt</p>
            <p>- Thời gian và ngày truy cập</p>
            <p>- Dữ liệu sử dụng (như thời gian sử dụng, tình trạng truy cập các tính năng)</p>
            <p className="tit">2. Sử dụng thông tin</p>
            <p>Các dữ liệu liên quan đến thiết bị mà chúng tôi thu thập sẽ được sử dụng cho các mục đích sau:</p>
            <p>- Cải thiện hiệu suất nền tảng và trải nghiệm người dùng</p>
            <p>- Giám sát và phân tích tình trạng sử dụng nền tảng</p>
            <p>- Nhận diện và sửa chữa các vấn đề trong nền tảng</p>
            <p>- Phát triển các tính năng và dịch vụ mới</p>
            <p className="tit">3. Chia sẻ thông tin</p>
            <p>Chúng tôi không bán, giao dịch hoặc cung cấp thông tin của bạn cho bên thứ ba. Chúng tôi chỉ chia sẻ thông tin của bạn trong các trường hợp sau:</p>
            <p>- Tuân thủ các yêu cầu của pháp luật và quy định</p>
            <p>- Bảo vệ và duy trì quyền và tài sản của chúng tôi</p>
            <p>- Ngăn chặn hoặc điều tra các hành vi vi phạm pháp luật hoặc vấn đề an ninh có thể xảy ra</p>
            <p className="tit">4. Bảo mật thông tin</p>
            <p>Chúng tôi áp dụng các biện pháp công nghệ và quản lý hợp lý để bảo vệ an toàn thông tin của bạn, ngăn chặn việc truy cập, sử dụng, sửa đổi hoặc tiết lộ trái phép.</p>
            <p className="tit">5. Quyền riêng tư của trẻ em</p>
            <p>Nền tảng của chúng tôi không dành cho trẻ em dưới 13 tuổi. Nếu chúng tôi phát hiện vô tình thu thập thông tin cá nhân của trẻ em dưới 13 tuổi, chúng tôi sẽ nhanh chóng xóa các thông tin đó.</p>
            <p className="tit">6. Thay đổi chính sách quyền riêng tư</p>
            <p>Chúng tôi có thể cập nhật chính sách quyền riêng tư này theo thời gian. Chúng tôi sẽ đăng phiên bản mới trên nền tảng và cập nhật ngày có hiệu lực. Vui lòng kiểm tra định kỳ để biết các biện pháp bảo vệ thông tin của chúng tôi.</p>
            <p className="tit">7. Liên hệ với chúng tôi</p>
            <p>Nếu bạn có bất kỳ câu hỏi hoặc ý kiến nào về chính sách quyền riêng tư này, vui lòng gửi email đến hòm thư của chúng tôi.</p>
        
        </div>
        </>
        }
        
        </BaseBox>
        
    </FullLaout>
}
export default Privacy