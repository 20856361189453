import { useEffect } from 'react'
import BaseBox from '../../component/BaseBox'
import BaseBoxBg from '../../component/BaseBoxBg'
import FullLaout from '../../component/FullLayout'
import NoticeItem from '../../component/NoticeItem'
import TopLeft from '../../component/TopLeft'
import useHomeList from '../../hooks/component/useHomeList'
import './index.less'
import BottomFalg from '../../component/BottomFalg'


const MoreNotice = ()=>{
    const { noticeList, getNoticeList } = useHomeList()

    
    useEffect(() => {
        getNoticeList()
    }, [])
    return <FullLaout title={'公告列表'} rightIcon={<></>} showFooter = {false}>
        <BaseBox style={{padding:"0 0.16rem",}} >
        {noticeList?.map((info, i) => (
                            <NoticeItem key={i} info = {info}  create_time={new Date().formatFullTime(info?.create_time)}/>
                        ))}  
        
        
        </BaseBox>
      
        {/* <BottomFalg  {... (noticeList?.length<10 && {style:{position:"fixed", bottom:"0.2rem", width:"100%", }})}/> */}
    </FullLaout>
}
export default MoreNotice