import { NavBar, NoticeBar, Image } from 'antd-mobile'
import "./index.less"
import avatar from "../../images/img/logo.png"
import useUserInfo from '../../hooks/common/useUserInfo'
import { useEffect } from "react"
import useAPI from '../../hooks/common/useAPI'
import { useTranslation } from 'react-i18next'
import useMoneyDeal from '../../hooks/common/useMoneyDeal'
const Header = ({headerBg = false,  showBottomLine, style, barStyle, backArrow, onBack, title, left, rightIcon, notice }) => {
    const {dealMoney} = useMoneyDeal()
    const {t} = useTranslation()
    const { state: userInfo , getUserInfo} = useUserInfo()
    const {getToken} = useAPI()
    const defaultRightIcon = <div >
        <div style={{ display: "flex", justifyContent:"space-between"}}>
            {userInfo?.username ? <><div style={{ marginRight:"0.08rem"}}>
                <div style={{marginBottom:"0.05rem"}}>{userInfo?.username}</div>
                <div style={{fontSize:"0.12rem", width:"1.1rem", overflow:"hidden"}}>{t('money')}:&nbsp;<span style={{fontSize:"0.14rem", marginTop:""}}>{dealMoney(userInfo?.money)}</span></div>
            </div>
                {/* <Image src={avatar} style={{ width: "0.35rem", height: "0.35rem", borderRadius: "0.2rem" }} /> */}
                </> : <>
                <div></div>
                <div className='tab-login' onClick={()=>{window.location.href = '#/login'}}>{t('login')}</div>
            </>}
        </div>

        {/* <DownlandOutline fontSize={24} />
    <div>下載</div> */}
    </div>

useEffect(()=>{
    let token = getToken()
    if(!userInfo?.username && token) getUserInfo()
}, [])


    return <div className={`${headerBg == true ? 'header-page-bg':'header-page'} ${showBottomLine == true ? 'bottom-line' : ''}`} style={{ backgroundColor: "", ...style }}>
        <NavBar backArrow={backArrow} left={left} right={rightIcon || defaultRightIcon} onBack={() => onBack ? onBack?.() : window.history.back()} style={{ color: "#fff", ...barStyle }} >
            {title}
        </NavBar>
        {notice && <NoticeBar content={notice} color='alert' closeable className='notice-bar' />}
    </div>
}
export default Header