import "./index.less"
import closePng from "../../images/svg/close-w.svg"
import a from "../../images/img/tip1.png"
import { useEffect, useState } from "react"


const IosTip = ({ onClose, visible }) => {


    const closeTip = () => {
        onClose?.()
    }


    useEffect(() => {

    }, [])

    return visible ? <div className="max-box">
        <div className="s-box-t">
            <div className="t-box">
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "2.5rem", fontSize: "0.18rem", }}>IOS輕量版安裝</div>
                <div><img src={closePng} onClick={() => closeTip()} /></div>
            </div>

            <div  className="detail-ios" >
                <div>1.將未知的來源打勾</div>
                <div><img src={require("../../images/img/tip2.png")}/></div>
                <div>2.打開手機【設置】</div>
                <div><img src={require('../../images/img/tip2.png')}/></div>
                <div>3.打開【一般或通用】，選擇【VPN與裝置管理】</div>
                <div><img src={require('../../images/img/tip3.png')}/></div>
                <div>4.點擊【Plus18 Games】,并點擊【安裝】</div>
                <div><img src={require('../../images/img/tip4.png')}/></div>
                <div>5.點擊【下一步】</div>
                <div><img src={require('../../images/img/tip5.png')}/></div>
                <div>6.繼續點擊【安裝】</div>
                <div><img src={require('../../images/img/tip6.png')}/></div>
                <div>7.在彈出框后，點擊【安裝】</div>
                <div><img src={require('../../images/img/tip7.png')}/></div>
            </div>

        </div>
    </div> : ''
}





export default IosTip