import axios from "axios";
import { useTranslation } from "react-i18next";
// const host = 'http://127.0.0.1:6501';
const host = process.env.REACT_APP_API_DOMAIN;

const useAPI = () => {
    const { t } = useTranslation()
    const setToken = (token) => {
        window.localStorage.setItem("_token", token)
    }
    const setCodeToken = (codeToken, token) => {
        window.localStorage.setItem(codeToken, token)
    }

    const getToken = () => {
        return window.localStorage.getItem("_token")
    }
    const getCodeToken = (codeToken) => {
        return window.localStorage.getItem(codeToken)
    }

    const setItemWithExpiry = (key, value) => {
        const now = new Date();
        const endOfDay = new Date();

        // 设置 endOfDay 为今天 23:59:59
  endOfDay.setHours(23, 59, 59, 999);
  // 计算剩余时间的毫秒数
  const remainingMillis = endOfDay - now;
console.log('rema', remainingMillis)
        const item = {
            value: value,
            expiry: now.getTime() + remainingMillis // 过期时间（毫秒）
        };
        localStorage.setItem(key, JSON.stringify(item));
    }

    const getItemWithExpiry = (key)=> {
        const itemStr = localStorage.getItem(key);
        console.log('itemstr', itemStr)
        if (!itemStr) {
          return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        // 检查是否过期
        if (now.getTime() > item.expiry) {
          localStorage.removeItem(key);
          return null;
        }
        return item.value;
      }

      const  cleanExpiredItems =() =>{
        const keys = Object.keys(localStorage);
        const now = new Date();
        keys.forEach(key => {
          const itemStr = localStorage.getItem(key);
          if (itemStr) {
            const item = JSON.parse(itemStr);
            if (now.getTime() > item.expiry) {
              localStorage.removeItem(key);
            }
          }
        });
      }
      
      
      

    const request = async (uri, param, method, showErr = true) => {
        let params = { loading: false, ...param, token: getToken() }
        let aa
        if (params.loading) {
            aa = window.show({
                icon: 'loading',
                content: t('loading'),
                duration: 0
            })
        }

        let data = JSON.stringify({ ...params })
        let res = await requestRaw(uri, data, method)
        if (!res) return false

        if (params.loading) {
            aa.close()
        }
        if (res?.data?.err_code == -100) {
            window.show({
                icon: 'fail',
                content: t('please_login'),
                duration: '1000',
                afterClose: () => {
                    // window.history.go(-1)
                    window.location.href = '#/login'
                }
            })
            return false
        } else if (res?.data?.result !== true) {
            if (showErr) {
                window.show({
                    icon: 'fail',
                    duration: '1000',
                    content: res?.data?.message,
                })
            }
            return false
        }

        return res.data
    }

    const requestRaw = async (uri, params, method = "GET") => {
        let url = host + uri
        let header = {
            'content-type': "application/json",
        }

        return await axios({
            url,
            method: method,
            data: params,
            responseType: "json",
            headers: header
        })

    }

    return { setToken, getToken, request, setCodeToken, getCodeToken ,setItemWithExpiry, getItemWithExpiry, cleanExpiredItems}
}

export default useAPI