import { useTranslation } from "react-i18next"
import useAPI from "./useAPI"


const useIsLogin = ()=>{

    const {t} = useTranslation()
    const {getToken} = useAPI()
    const isLogin =()=>{
        if(!getToken()){
            window.show({
                icon: 'fail',
                content: t('please_login'),
                duration:'500',
                afterClose:()=>{
                    window.location.href = '#/login'
                }
            })
            return false
        }
        return  true
    }

    return {isLogin}
}

export default useIsLogin